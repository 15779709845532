import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { URL } from './constants.js';
import './Admin.css';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next'

function Admin() {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const handleFormClick = (url) => {
        navigate(url);
    };

    useEffect(() => {
        async function check_for_admin() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    navigate("/");
                    return false;
                } else {
                    try {
                        const response = await fetch(`${URL}/api/users/get_username`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ user_id: decodedToken.id })
                        });
                        const data = await response.json();
                        if (data["user_exists"] === false) {
                            Cookies.remove('login-token-jwt');
                            navigate("/");
                            return false;
                        } else {
                            const response = await fetch(`${URL}/api/users/is_admin`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ user_id: decodedToken.id })
                            });
                            const data2 = await response.json();
                            if (!data2["is_admin"]) {
                                navigate("/");
                                return false;
                            }
                            return true;
                        }
                    } catch (error) {
                        console.error('Error fetching username:', error);
                    }
                }
            }
            else {
                navigate("/");
                return false;
            }
        }

        check_for_admin();
    }, []);

    return (
        <>
            <div className='list'>
                <h3 className='header-3-config'>{t('panoul_de_administrator')}</h3>
                <h3 className='header-6-config'>{t('da_click_pe_oricare_optiune_din_cele_de_mai_jos_pentru_a_naviga_la_pagina_dorita')}</h3>
                <div className="col-12 documents-container mt-5">
                    <div className="list-item">
                        <a href="/admin/config_assignment">
                            <div className="card m-2">
                                <div className="card-body">
                                    <p className="card-title text-start">Config Assignment</p>
                                    <p className="card-text text-start">{t('asigneaza_care_configuri_sunt_publice_care_pot_sa_fie_vazute_de_anumiti_utilizatori_etc')}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="list-item">
                        <a href="/admin/config_update">
                            <div className="card m-2">
                                <div className="card-body">
                                    <p className="card-title text-start">Config Update</p>
                                    <p className="card-text text-start">{t('actualizeaza_un_anumit_config_pentru_a_l_modifica')}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="list-item">
                        <a href="/admin/config_update">
                            <div className="card m-2">
                                <div className="card-body">
                                    <p className="card-title text-start">Config Create Delete</p>
                                    <p className="card-text text-start">{t('creeaza_vizualizeaza_sau_sterge_configuri')}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Admin;
