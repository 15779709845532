import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { URL } from './constants.js';
import './ForgotPassword.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next'

function ForgotPassword() {
    const [loginStatus, setLoginStatus] = useState(false);
    const [formDataForgotPassword, setFormForgotPassword] = useState({
        email_hashed: '',
        code: '',
        new_password: ''
    });
    const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
    const [forgotPasswordCodeSuccess, setForgotPasswordCodeSuccess] = useState(false);
    const [forgotPasswordCodeMessage, setForgotPasswordCodeMessage] = useState('');
    const [forgotPasswordChangeSuccess, setForgotPasswordChangeSuccess] = useState(false);
    const [forgotPasswordChangeMessage, setForgotPasswordChangeMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [loader3, setLoader3] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const { t } = useTranslation()

    const handleChangeForgotPassword = (e) => {
        const { name, value } = e.target;
        setFormForgotPassword({
            ...formDataForgotPassword,
            [name]: value
        });

        if (name === 'confirm_password') {
            setPasswordMatch(formDataForgotPassword.new_password === value);
        }
    };

    const handleSubmitForgotPassword = async (e) => {
        e.preventDefault();
        setForgotPasswordMessage('');
        try {
            setLoader(true);
            const response = await fetch(`${URL}/api/users/forgot_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email_hashed: formDataForgotPassword.email_hashed})
            });
            const data = await response.json();
            setLoader(false);
            if (data["success"] === true) {
                setForgotPasswordSuccess(true);
                setForgotPasswordMessage("Verifica email-ul. Noi am trimis un cod");
            }
            else {
                setForgotPasswordSuccess(false);
                setForgotPasswordMessage("Aceasta adresa de email nu exista in baza noastra de date");
            }
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    };

    const handleSubmitForgotPasswordCode = async (e) => {
        e.preventDefault();
        try {
            setLoader2(true);
            const response = await fetch(`${URL}/api/users/validate_code_forgot_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email_hashed: formDataForgotPassword.email_hashed,
                    code: formDataForgotPassword.code
                })
            });
            const data = await response.json();
            setLoader2(false);
            
            if (data["success"] === true) {
                setForgotPasswordCodeSuccess(true);
                setForgotPasswordCodeMessage("Codul este valid. Te rugam sa introduci noua parola");
            }
            else {
                setForgotPasswordCodeSuccess(false);
                setForgotPasswordCodeMessage("Acest cod nu este valid");
            }
        } catch (error) {
            setLoader2(false);
            console.error('Error:', error);
        }
    };

    const handleSubmitChangePassword = async (e) => {
        e.preventDefault();
        if (!passwordMatch) {
            alert("Passwords do not match!");
            return;
        }
        try {
            setLoader3(true);
            const response = await fetch(`${URL}/api/users/change_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email_hashed: formDataForgotPassword.email_hashed,
                    new_password: formDataForgotPassword.new_password
                })
            });
            const data = await response.json();
            setLoader3(false);
            
            if (data["success"] === true) {
                setForgotPasswordChangeSuccess(true);
                setForgotPasswordChangeMessage("Parola a fost schimbata cu succes");
            }
            else {
                setForgotPasswordChangeSuccess(false);
                setForgotPasswordChangeMessage("Email oferit nu se afla in baza noastra de date");
            }
        } catch (error) {
            setLoader3(false);
            console.error('Error:', error);
        }
    };

    const handleLogoutFP = () => {
        const token = Cookies.get('login-token-jwt');
        if (token) {
            Cookies.remove('login-token-jwt');
        }
        setLoginStatus(false);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    setLoginStatus(false);
                } else {
                    setLoginStatus(true);
                }
            }
        }
    
        setLoader(false);
        setLoader2(false);
        setLoader3(false);
        fetchData();
    }, []);

    return (
        <>
            {
                loginStatus &&
                <>
                    <div className="login-div col-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{t('esti_deja_contectat_doresti_sa_te_reconectezi')} <span className="color-text extra_underline cursor-pointer" onClick={handleLogoutFP}>{t('click_aici')}</span></h5>
                            </div>
                        </div>
                        
                    </div>
                </>
            }
            {
                !loginStatus &&
                <>
                    <div className="login-div col-4">
                        <div className="card">
                            <div className="card-body">
                            <h5 className="card-title">{t('parola_uitata')}</h5>
                            <form onSubmit={handleSubmitForgotPassword}>
                                <label className="form-label">Email</label>
                                <input className="form-control mb-3" type="text" name="email_hashed" value={formDataForgotPassword.email_hashed} onChange={handleChangeForgotPassword} required />
                                <button className="btn btn-success" type="submit" disabled={loader}>{t('trimite_cod_pe_email')}</button>
                            </form>
                            </div>
                        </div>                        
                    </div>
                </>
            }
            {
                !loginStatus &&
                loader &&
                <>
                    <div className="loader">
                        <div style={{"--i": 1}}></div>
                        <div style={{"--i": 2}}></div>
                        <div style={{"--i": 3}}></div>
                        <div style={{"--i": 4}}></div>
                    </div>
                </>
            }
            {
                !loginStatus &&
                forgotPasswordSuccess &&
                <>
                    <div className="login-div fp-div">
                        <span className='good-text'>{forgotPasswordMessage}</span>
                    </div>
                </>
            }
            {
                !loginStatus &&
                !forgotPasswordSuccess &&
                <>
                    <div className="login-div fp-div">
                        <span className='bad-text'>{forgotPasswordMessage}</span>
                    </div>
                </>
            }
            {
                !loginStatus &&
                <>
                    <div className="login-div col-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{t('validare_cod_email')}</h5>
                                <form onSubmit={handleSubmitForgotPasswordCode}>
                                    <label className="form-label">Email</label>
                                    <input className="form-control mb-3" type="text" name="email_hashed" value={formDataForgotPassword.email_hashed} onChange={handleChangeForgotPassword} required />
                                    <label className="form-label">{t('codul')}</label>
                                    <input className="form-control" type="text" name="code" value={formDataForgotPassword.code} onChange={handleChangeForgotPassword} required />
                                    <button className="btn btn-success mt-3" type="submit" disabled={loader2}>{t('validare_cod')}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                !loginStatus &&
                loader2 &&
                <>
                    <div className="loader">
                        <div style={{"--i": 1}}></div>
                        <div style={{"--i": 2}}></div>
                        <div style={{"--i": 3}}></div>
                        <div style={{"--i": 4}}></div>
                    </div>
                </>
            }
            {
                !loginStatus &&
                !forgotPasswordCodeSuccess &&
                <>
                    <div className="login-div fp-div">
                        <span className='bad-text'>{forgotPasswordCodeMessage}</span>
                    </div>
                </>
            }
            {
                !loginStatus &&
                forgotPasswordCodeSuccess &&
                <>
                    <div className="login-div fp-div">
                        <span className='good-text'>{forgotPasswordCodeMessage}</span>
                        <form onSubmit={handleSubmitChangePassword}>
                            <input className="input-form-login" type="text" name="email_hashed" value={formDataForgotPassword.email_hashed} onChange={handleChangeForgotPassword} placeholder="Email" required />
                            <input className="input-form-login" type="password" name="new_password" value={formDataForgotPassword.new_password} onChange={handleChangeForgotPassword} placeholder="Noua Parola" required />
                            <input className="input-form-login" type="password" name="confirm_password" value={formDataForgotPassword.confirm_password} onChange={handleChangeForgotPassword} placeholder="Noua Parola" required />
                            <button className="button-form-login" type="submit" disabled={loader3}>Incarca Noua Parola</button>
                        </form>
                    </div>
                </>
            }
            {
                !loginStatus &&
                forgotPasswordCodeSuccess &&
                !passwordMatch &&
                <p className='color-text bad-input'>Parolele nu sunt la fel!<br/></p>
            }
            {
                !loginStatus &&
                loader3 &&
                <>
                    <div className="loader">
                        <div style={{"--i": 1}}></div>
                        <div style={{"--i": 2}}></div>
                        <div style={{"--i": 3}}></div>
                        <div style={{"--i": 4}}></div>
                    </div>
                </>
            }
            {
                !loginStatus &&
                forgotPasswordCodeSuccess &&
                forgotPasswordChangeSuccess &&
                <>
                    <div className="login-div fp-div">
                        <span className='good-text'>{forgotPasswordChangeMessage}</span>
                    </div>
                </>
            }
            {
                !loginStatus &&
                forgotPasswordCodeSuccess &&
                !forgotPasswordChangeSuccess &&
                <>
                    <div className="login-div fp-div">
                        <span className='bad-text'>{forgotPasswordChangeMessage}</span>
                    </div>
                </>
            }
        </>
    );
}

export default ForgotPassword;
