import './NotFound.css';
import { useTranslation } from 'react-i18next'


function NotFound() {
    const { t } = useTranslation()
    return(
        <>
            <header className="top-header"></header>
            <div className="lamp__wrap">
                <div className="lamp">
                    <div className="cable"></div>
                    <div className="cover"></div>
                    <div className="in-cover">
                        <div className="bulb"></div>
                    </div>
                    <div className="light"></div>
                </div>
            </div>
            <section className="error">
                <div className="error__content">
                    <div className="error__message message">
                        <h1 className="message__title">{t('pagina_pe_care_o_cauti_nu_o_avem')}!</h1>
                        <p className="message__text">{t('ne_pare_rau_dar_pagina_pe_care_ai_incercat_sa_o_accesezi_nu_exista')}!</p>
                    </div>
                    <div className="error__nav e-nav">
                        <a href="/" className="e-nav__link">{t('acasa')}</a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NotFound;
