import './Documents.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { URL } from './constants.js';
import { useTranslation } from 'react-i18next'

function NotFound() {
    const [configs, setConfigs] = useState([]);
    const { t } = useTranslation()
    useEffect(() => {
        async function fetchData() {
           
            const response = await axios.get(`${URL}/api/config/get_all_valid_public_configs`);
            setConfigs(response.data);
        }        

        fetchData();
    }, []);
    return(
        <>
            <div className="m-3 col-11">
                <h3 className="mt-3 col-12 mb-3 text-start">{t('toate_documentele')}</h3>
                <div className="col-12 documents-container">
                    {
                        configs.map(config => (
                            <div className="list-item">
                                <a href={"/form/" + config.form_name_id}>
                                    <div className="card m-2">
                                        <div className="card-body">
                                            <p className="card-title text-start">{config.form_display_name}</p>
                                            <p className="card-text text-start">{config.form_description}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            )
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default NotFound;
