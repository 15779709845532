import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ConfigList.css';
import { URL } from './constants.js';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next'

const ConfigList = () => {
    const [configs, setConfigs] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation()

    const fetchData = async () => {
        try {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    const response = await axios.get(`${URL}/api/config/get_all_valid_public_configs`);
                    setConfigs(response.data);
                } else {
                    const response = await fetch(`${URL}/api/config/get_all_user_configs`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({user_id: decodedToken.id})
                    });
                    const data = await response.json();
                    setConfigs(data);
                }
            }
            else{
                const response = await axios.get(`${URL}/api/config/get_all_valid_public_configs`);
                setConfigs(response.data);
            }
        } catch (error) {
            console.error('Error fetching configs:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFormClick = (formName) => {
        navigate(`/form/${formName}`);
    };

    return (
        <>
            <h2 className='header-2-config'>{t('genereaza_devize_de_reparatii_auto')}</h2>
            <h3 className='header-3-config'>{t('gratuit')}</h3>

            <div className='phone-padding'>
                <ul className='ul-reclama extra-text-ul-reclama'>
                    <li>- {t('introduci_datele_si_descarci_documentul')}</li>
                    <li>- {t('devize_de_estimare_si_de_reparatii')}</li>
                    <li>- {t('nu_trebuie_sa_instalezi_nimic')}</li>
                    <li>- {t('nu_trebuie_sa_te_inregistrezi')}</li>
                    <li>- {t('gratuit_pentru_totdeauna')}</li>
                </ul>
            </div>

            <br></br>
            <hr className='separator'></hr>
            <br></br>

            <div className='list'>
                <h3 className='header-3-config'>{t('incepe_acum_devizul_tau')}</h3>
                <h3 className='header-6-config'>{t('daca_crezi_ca_lipseste_ceva_sau_vrei_sa_adaugam_ceva_nou_lasa_ne_feedback_si_ne_punem_pe_treaba')}</h3>
                <div className="col-12 documents-container mt-5">
                    {
                        configs.map(config => (
                                <div className="list-item">
                                    <a href={'/form/' + config.form_name_id}>
                                        <div className="card m-2">
                                            <div className="card-body">
                                                <p className="card-title text-start">{config.form_display_name}</p>
                                                <p className="card-text text-start">{config.form_description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
            <br></br>
            <hr className='separator'></hr>
            <br></br>

            <div className='phone-padding'>
                <h3 className='header-3-config'>{t('daca_iti_faci_cont_beneficiezi_de')}</h3>
                <ul className='ul-reclama'>
                    <li>- {t('datele_firmei_sau_atelierului_iti_sunt_precompletate_de_fiecare_data')}</li>
                    <li>- {t('poti_salva_clienti_si_masini_pentru_cei_ce_revin')}</li>
                    <li>- {t('poti_crea_deviz_autorizat_rar')}</li>
                    <li>- {t('ai_un_isotric_al_devizelor')}</li>
                </ul>
            </div>
            <br/>
        </>
    );
};

export default ConfigList;
