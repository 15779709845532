import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { URL } from './constants.js';
import './AdminConfigCrd.css';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next'

function AdminConfigCrd() {
    const [configs, setConfigs] = useState([]);
    const [loader, setLoader] = useState(false);
    const [createDefaultConfigSuccess, setCreateDefaultConfigSuccess] = useState(false);
    const [createDefaultConfigMessage, setCreateDefaultConfigMessage] = useState('');
    const [deleteConfigSuccess, setDeletetConfigSuccess] = useState(false);
    const [deleteConfigMessage, setDeleteConfigMessage] = useState('');
    const [formDataNewConfig, setFormDataNewConfig] = useState({
        config_name: '',
        public: false,
    });
    const [formDataDeleteConfig, setFormDataDeleteConfig] = useState({
        config_name: '',
    });
    const navigate = useNavigate();
    const { t } = useTranslation()

    const fetchData = async () => {
        try {
            const response = await axios.get(`${URL}/api/config/get_config_manager`);
            setConfigs(response.data);
        } catch (error) {
            console.error('Error fetching configs:', error);
        }
    };

    useEffect(() => {
        async function check_for_admin() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    navigate("/");
                    return false;
                } else {
                    try {
                        const response = await fetch(`${URL}/api/users/get_username`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ user_id: decodedToken.id })
                        });
                        const data = await response.json();
                        if (data["user_exists"] === false) {
                            Cookies.remove('login-token-jwt');
                            navigate("/");
                            return false;
                        } else {
                            const response = await fetch(`${URL}/api/users/is_admin`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ user_id: decodedToken.id })
                            });
                            const data2 = await response.json();
                            if (!data2["is_admin"]) {
                                navigate("/");
                                return false;
                            }
                            return true;
                        }
                    } catch (error) {
                        console.error('Error fetching username:', error);
                    }
                }
            }
            else {
                navigate("/");
                return false;
            }
        }

        if(check_for_admin()) {
            fetchData();
            setLoader(false);
        }
    }, []);

    const createDefaultConfig = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            const response = await fetch(`${URL}/api/config/create_new_config`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    config_name: formDataNewConfig.config_name,
                    public: formDataNewConfig.public
                })
            });
            const data = await response.json();
            setLoader(false);
            
            setCreateDefaultConfigSuccess(data["success"]);
            setCreateDefaultConfigMessage(data["message"]);
            fetchData();
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    };

    const handleFormData = (e) => {
        const { name, value } = e.target;
        setFormDataNewConfig({
            ...formDataNewConfig,
            [name]: value
        });
    };

    const handleFormDataDelete = (e) => {
        const { name, value } = e.target;
        setFormDataDeleteConfig({
            ...formDataDeleteConfig,
            [name]: value
        });
    };

    const deleteConfig = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            const response = await fetch(`${URL}/api/config/delete_config`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    config_name: formDataDeleteConfig.config_name,
                })
            });
            const data = await response.json();
            setLoader(false);
            
            setDeletetConfigSuccess(data["success"]);
            setDeleteConfigMessage(data["message"]);
            fetchData();
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    };

    return (
        <>
            <h5 className="caption-table">{t('toate_configurile_curente')}</h5>
            <div className="col-6 table-scrollable mb-3">
                <table className="table table-striped table-hover caption-top">
                    <thead>
                        <tr>
                            <th>Config ID</th>
                            <th>Config Name</th>
                            <th>Public Config</th>
                            <th>Config Assignment Emails</th>
                            <th>Config Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        {configs.map(config => (
                            <tr key={config.id}>
                                <td>{config.id}</td>
                                <td>{config.config_name}</td>
                                <td>{`${config.public}`}</td>
                                <td>{config.config_assignments ? config.config_assignments.join(', ') : ''}</td>
                                <td>{config.config_error}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex gap-5 col-10 justify-content-center">
                <div className='card col-4'>
                    <div className="card-body">
                        <h4 className="card-title">{t('creeaza_un_config_default')}</h4>
                        {
                            loader &&
                            <>
                                <div className="loader">
                                    <div style={{"--i": 1}}></div>
                                    <div style={{"--i": 2}}></div>
                                    <div style={{"--i": 3}}></div>
                                    <div style={{"--i": 4}}></div>
                                </div>
                            </>
                        }
                        {
                            createDefaultConfigSuccess &&
                            <>
                                <div className="login-div">
                                    <span className='text-success'>{createDefaultConfigMessage}</span>
                                </div>
                            </>
                        }
                        {
                            !createDefaultConfigSuccess &&
                            <>
                                <div className="login-div">
                                    <span className='text-danger'>{createDefaultConfigMessage}</span>
                                </div>
                            </>
                        }
                        <form onSubmit={createDefaultConfig}>
                            <label className="form-label mt-3">{t('nume_config')} (fara .yml)</label>
                            <input className="form-control mb-3" type="text" name="config_name" value={formDataNewConfig.config_name} onChange={handleFormData} required />
                            <label className="form-label">{t('este_public')}?</label>
                            <select
                                id="public"
                                name="public"
                                onChange={handleFormData}
                                className='form-control'
                            >
                                <option key={`false`} value={false}>False</option>
                                <option key={`true`} value={true}>True</option>
                            </select>
                            <button className="btn btn-success mt-3" type="submit" disabled={loader}>{t('creeaza_un_config')}</button>
                        </form>
                    </div>
                </div>
                <div className='card col-4'>
                    <div className="card-body">
                        <h4 className='card-title'>{t('sterge_un_config')}</h4>
                        {
                            loader &&
                            <>
                                <div className="loader">
                                    <div style={{"--i": 1}}></div>
                                    <div style={{"--i": 2}}></div>
                                    <div style={{"--i": 3}}></div>
                                    <div style={{"--i": 4}}></div>
                                </div>
                            </>
                        }
                        {
                            deleteConfigSuccess &&
                            <>
                                <div className="login-div">
                                    <span className='text-success'>{deleteConfigMessage}</span>
                                </div>
                            </>
                        }
                        {
                            !deleteConfigSuccess &&
                            <>
                                <div className="login-div">
                                    <span className='text-danger'>{deleteConfigMessage}</span>
                                </div>
                            </>
                        }
                        <form onSubmit={deleteConfig}>
                            <label className="form-label mt-3">Nume config (fara .yml)</label>
                            <input className="form-control" type="text" name="config_name" value={formDataDeleteConfig.config_name} onChange={handleFormDataDelete} required />
                            <button className="btn btn-danger mt-3" type="submit" disabled={loader}>{t('sterge_un_config')}</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminConfigCrd;
