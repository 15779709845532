import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { URL } from './constants.js';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

function Login() {
    const navigate = useNavigate();
    const [loginStatus, setLoginStatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loginMessage, setLoginMessage] = useState('');
    const [formDataLogin, setFormDataLogin] = useState({
        username_hashed: '',
        password_hashed: '',
    });
    const { t } = useTranslation()

    const handleChangeLogin = (e) => {
        const { name, value } = e.target;
        setFormDataLogin({
            ...formDataLogin,
            [name]: value
        });
    };

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            const response = await fetch(`${URL}/api/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formDataLogin)
            });
            const data = await response.json();
            setLoader(false);
            if (data["connected"] === true) {
                setLoginStatus(true);
                Cookies.set('login-token-jwt', data["message"])
                navigate('/');
                window.location.reload();
            }
            else {
                setLoginStatus(false);
                setLoginMessage(data["message"]);
            }
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    };

    const handleLogoutLogin = () => {
        const token = Cookies.get('login-token-jwt');
        if (token) {
            Cookies.remove('login-token-jwt');
        }
        setLoginStatus(false);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    setLoginStatus(false);
                } else {
                    setLoginStatus(true);
                }
            }
        }
    
        fetchData();
        setLoader(false);
    }, []);

    return (
        <>
            {
                !loginStatus &&
                <div className="login-div col-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('autentificare')}</h5>
                            <form onSubmit={handleSubmitLogin}>
                            <label className="form-label">{t('nume_utilizator_email')}</label>
                            <input className="form-control mb-3" type="text" name="username_hashed" value={formDataLogin.username_hashed} onChange={handleChangeLogin} required />
                            <label className="form-label">{t('parola')}</label>
                            <input className="form-control" type="password" name="password_hashed" value={formDataLogin.password_hashed} onChange={handleChangeLogin} required />                    
                            <button className="btn btn-success mt-3" type="submit" disabled={loader}>{t('autentificare')}</button>
                            <span className="color-text">{loginMessage}<br/></span>
                            <h6 className='card-subtitle mt-3'>{t('ai_uitat_parola')} <a className="color-text hover-text" href="/forgot_password">{t('click_aici')}</a></h6>
                            <h6 className='card-subtitle mt-3'>{t('nu_ai_cont')} <a className="color-text hover-text" href="/register">{t('click_aici')}</a></h6>
                        </form>
                        </div>
                    </div>                    
                </div>
            }
            {
                loginStatus &&
                <>
                    <div className="login-div">
                        <span className='color-text'>{t('esti_deja_contectat_doresti_sa_te_reconectezi')} </span>
                        <span className="color-text hover-text extra_underline" onClick={handleLogoutLogin}>{t('click_aici')}</span>
                    </div>
                </>
            }
            {
                loader &&
                <>
                    <div className="loader">
                        <div style={{"--i": 1}}></div>
                        <div style={{"--i": 2}}></div>
                        <div style={{"--i": 3}}></div>
                        <div style={{"--i": 4}}></div>
                    </div>
                </>
            }
        </>
    );
}

export default Login;
