import React from 'react';
import './Help.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function Help() {
    return (
        <>
            <h3 className='header-3-config'>@TODO: Asteapta FAQ, dupa care implementeaza</h3>
        </>
    );
}

export default Help;
