import React, { useState, useEffect } from 'react';
import { URL } from './constants.js'
import './ApiTesting.css';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';


function ApiTesting() {
    const [formDataRegister, setFormDataRegister] = useState({
        email_hashed: '',
        username_hashed: '',
        password_hashed: '',
        surname: '',
        forename: '',
        address_street: '',
        address_city: '',
        address_number: '',
        phone_number: ''
    });
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [registrationMessage, setRegistrationMessage] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const [formDataValidateCode, setFormDataValidateCode] = useState({
        email_hashed: '',
        code: '',
    });
    const [codeMessage, setCodeMessage] = useState('');
    const [codeSuccess, setCodeSuccess] = useState(false);

    const [formDataLogin, setFormDataLogin] = useState({
        email_hashed: '',
        password_hashed: '',
    });

    const [loginMessage, setLoginMessage] = useState('');
    const [loginSuccess, setLoginSuccess] = useState(false);

    const [logoutMessage, setLogoutMessage] = useState('');
    const [logoutSuccess, setLogoutSuccess] = useState(false);

    const [formDataForgotPassword, setFormForgotPassword] = useState({
        email_hashed: '',
        code: '',
        new_password: ''
    });
    const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
    const [forgotPasswordCodeSuccess, setForgotPasswordCodeSuccess] = useState(false);
    const [forgotPasswordCodeMessage, setForgotPasswordCodeMessage] = useState('');
    const [forgotPasswordChangeSuccess, setForgotPasswordChangeSuccess] = useState(false);
    const [forgotPasswordChangeMessage, setForgotPasswordChangeMessage] = useState('');

    const [selectedTable, setSelectedTable] = useState('');
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [tableSchemas, setTableSchemas] = useState([]);

    useEffect(() => {
        // Token
        const token = Cookies.get('login-token-jwt');
        if (token) {
            // Parse token payload to get expiration time
            const decodedToken = jwtDecode(token);
            const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
    
            // Check if current time is greater than expiration time
            if (Date.now() >= expirationTime) {
                // Token has expired, remove it from cookies
                Cookies.remove('login-token-jwt');
                setLoginMessage("User is not logged in");
                setLoginSuccess(false);
            } else {
                // Token is still valid
                setLoginMessage("User is already logged in");
                setLoginSuccess(true);
            }
        }

        // Schemas
        fetchTableSchemas();
    }, []);

    const fetchTableSchemas = async () => {
        try {
            const response = await fetch(`${URL}/api/debug_api/get_all_schemas`);
            const data = await response.json();
            setTableSchemas(data["schemas"]);
            setSelectedTable(data["schemas"][0]);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleTableSelect = (e) => {
        setSelectedTable(e.target.value);
    };

    const handleTableGet = async () => {
        try {
            const response = await fetch(`${URL}/api/debug_api/get_table_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({schema_name: selectedTable})
            });
            const data = await response.json();
            
            setTableColumns(data.table_column_names);
            setTableData(data.all_data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChangeValidateCode = (e) => {
        const { name, value } = e.target;
        setFormDataValidateCode({
            ...formDataValidateCode,
            [name]: value
        });
    };

    const handleSubmitValidateCode = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${URL}/api/users/validate_user_code_account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formDataValidateCode)
            });
            const data = await response.json();
            setCodeSuccess(data["success"]);
            setCodeMessage(data["message"]);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChangeRegister = (e) => {
        const { name, value } = e.target;
        setFormDataRegister({
            ...formDataRegister,
            [name]: value
        });

        // Check if passwords match
        if (name === 'confirmPassword') {
            setPasswordMatch(formDataRegister.password_hashed === value);
        }
    };

    const handleSubmitRegister = async (e) => {
        e.preventDefault();
        if (!passwordMatch) {
            alert("Passwords do not match!");
            return;
        }
        try {
            // Check Credentials
            const response = await fetch(`${URL}/api/users/check_credentials_register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email_hashed: formDataRegister.email_hashed,
                    username_hashed: formDataRegister.username_hashed,
                    password_hashed: formDataRegister.password_hashed
                })
            });
            const are_valid_cred = await response.json();

            if (are_valid_cred["are_good_credentials"] === true) {
                // Create User
                const response = await fetch(`${URL}/api/users/register_user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formDataRegister)
                });
                const data = await response.json();
                
                if (data["is_created"] === true) {
                    // Generate User Code

                    const response = await fetch(`${URL}/api/users/generate_user_code`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({user_id: data["user_id"]})
                    });
                    const data_code = await response.json();

                    if (data_code["success"] === true){
                        setRegistrationMessage("Inregistrare cu success. Valideaza codul primit pe email.");
                        setRegistrationSuccess(true);
                    }
                    else {
                        setRegistrationMessage(`Eroare: ${data_code["message"]}`);
                        setRegistrationSuccess(false);
                    }
                }
                else {
                    setRegistrationMessage(`Eroare: ${data["message"]}`);
                    setRegistrationSuccess(false);
                }
            }
            else {
                setRegistrationMessage(`Eroare: ${are_valid_cred["message"]}`);
                setRegistrationSuccess(false);
            }
            
           
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChangeLogin = (e) => {
        const { name, value } = e.target;
        setFormDataLogin({
            ...formDataLogin,
            [name]: value
        });
    };

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${URL}/api/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formDataLogin)
            });
            const data = await response.json();
            
            if (data["connected"] === true) {
                setLoginMessage("User is now logged in");
                setLoginSuccess(true);
                Cookies.set('login-token-jwt', data["message"])
            }
            else {
                setLoginMessage(data["message"]);
                setLoginSuccess(false);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLogout = () => {
        const token = Cookies.get('login-token-jwt');
        if (token) {
            Cookies.remove('login-token-jwt');
            setLogoutSuccess(true);
            setLogoutMessage("User successfully logged out");

            setLoginSuccess(false);
            setLoginMessage("User is not logged in");
        }
        else {
            setLogoutSuccess(false);
            setLogoutMessage("User is not logged in. Nothing to log out of");
        }
    };

    const handleChangeForgotPassword = (e) => {
        const { name, value } = e.target;
        setFormForgotPassword({
            ...formDataForgotPassword,
            [name]: value
        });
    };

    const handleSubmitForgotPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${URL}/api/users/forgot_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email_hashed: formDataForgotPassword.email_hashed})
            });
            const data = await response.json();
            
            if (data["success"] === true) {
                setForgotPasswordSuccess(true);
                setForgotPasswordMessage("Check your email. A code has been sent");
            }
            else {
                setForgotPasswordSuccess(false);
                setForgotPasswordMessage("This email does not exist in our database");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmitForgotPasswordCode = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${URL}/api/users/validate_code_forgot_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email_hashed: formDataForgotPassword.email_hashed,
                    code: formDataForgotPassword.code
                })
            });
            const data = await response.json();
            
            if (data["success"] === true) {
                setForgotPasswordCodeSuccess(true);
                setForgotPasswordCodeMessage("Code is valid. Please submit the new password");
            }
            else {
                setForgotPasswordCodeSuccess(false);
                setForgotPasswordCodeMessage("Code is invalid. Try again");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmitChangePassword = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${URL}/api/users/change_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email_hashed: formDataForgotPassword.email_hashed,
                    new_password: formDataForgotPassword.new_password
                })
            });
            const data = await response.json();
            
            if (data["success"] === true) {
                setForgotPasswordChangeSuccess(true);
                setForgotPasswordChangeMessage("Password changed with success");
            }
            else {
                setForgotPasswordChangeSuccess(false);
                setForgotPasswordChangeMessage("User that tried to change password does not exist");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className="signup">
                <h2>Register User</h2>
                <form onSubmit={handleSubmitRegister}>
                    <input className="input-form" type="email" name="email_hashed" value={formDataRegister.email_hashed} onChange={handleChangeRegister} placeholder="Email" required />
                    <input className="input-form" type="text" name="username_hashed" value={formDataRegister.username_hashed} onChange={handleChangeRegister} placeholder="Username" required />
                    <input className="input-form" type="password" name="password_hashed" value={formDataRegister.password_hashed} onChange={handleChangeRegister} placeholder="Password" required />
                    <input className="input-form" type="password" name="confirmPassword" value={formDataRegister.confirmPassword} onChange={handleChangeRegister} placeholder="Confirm Password" required />
                    {!passwordMatch && <p style={{ color: 'red' }}>Passwords do not match!</p>}
                    <input className="input-form" type="text" name="surname" value={formDataRegister.surname} onChange={handleChangeRegister} placeholder="Surname (Optional)" />
                    <input className="input-form" type="text" name="forename" value={formDataRegister.forename} onChange={handleChangeRegister} placeholder="Forename (Optional)" />
                    <input className="input-form" type="text" name="address_street" value={formDataRegister.address_street} onChange={handleChangeRegister} placeholder="Street (Optional)" />
                    <input className="input-form" type="text" name="address_city" value={formDataRegister.address_city} onChange={handleChangeRegister} placeholder="City (Optional)" />
                    <input className="input-form" type="text" name="address_number" value={formDataRegister.address_number} onChange={handleChangeRegister} placeholder="Number (Optional)" />
                    <input className="input-form" type="tel" name="phone_number" value={formDataRegister.phone_number} onChange={handleChangeRegister} placeholder="Phone Number (Optional)" />
                    <button className="button-form" type="submit">Register</button>
                    {registrationSuccess && <p style={{ color: 'green' }}>{registrationMessage}</p>}
                    {!registrationSuccess && <p style={{ color: 'red' }}>{registrationMessage}</p>}
                </form>
            </div>
            <div className="signup">
                <h2>Validate Code Email</h2>
                <form onSubmit={handleSubmitValidateCode}>
                    <input className="input-form" type="email" name="email_hashed" value={formDataValidateCode.email_hashed} onChange={handleChangeValidateCode} placeholder="Email" required />
                    <input className="input-form" type="text" name="code" value={formDataValidateCode.code} onChange={handleChangeValidateCode} placeholder="Validation Code" required />
                    <button className="button-form" type="submit">Validate Code</button>
                    {codeSuccess && <p style={{ color: 'green' }}>{codeMessage}</p>}
                    {!codeSuccess && <p style={{ color: 'red' }}>{codeMessage}</p>}
                </form>
            </div>
            <div className="signup">
                <h2>Login</h2>
                <form onSubmit={handleSubmitLogin}>
                    <input className="input-form" type="text" name="username_hashed" value={formDataLogin.username_hashed} onChange={handleChangeLogin} placeholder="Username" required />
                    <input className="input-form" type="password" name="password_hashed" value={formDataLogin.password_hashed} onChange={handleChangeLogin} placeholder="Password" required />
                    <button className="button-form" type="submit">Login</button>
                    {loginSuccess && <p style={{ color: 'green' }}>{loginMessage}</p>}
                    {!loginSuccess && <p style={{ color: 'red' }}>{loginMessage}</p>}
                </form>
            </div>
            <div className="signup">
                <h2>Logout</h2>
                <button className="button-form" type="button" onClick={handleLogout}>Logout</button>
                {logoutSuccess && <p style={{ color: 'green' }}>{logoutMessage}</p>}
                {!logoutSuccess && <p style={{ color: 'red' }}>{logoutMessage}</p>}
            </div>
            <div className="signup">
                <h2>Forgot Password</h2>
                <form onSubmit={handleSubmitForgotPassword}>
                    <input className="input-form" type="text" name="email_hashed" value={formDataForgotPassword.email_hashed} onChange={handleChangeForgotPassword} placeholder="Email" required />
                    <button className="button-form" type="submit">Forgot Password</button>
                </form>
                {!forgotPasswordSuccess && <p style={{ color: 'red' }}>{forgotPasswordMessage}</p>}
                {
                    forgotPasswordSuccess &&
                    <>
                        <p style={{ color: 'green' }}>{forgotPasswordMessage}</p>
                        <form onSubmit={handleSubmitForgotPasswordCode}>
                            <input className="input-form" type="text" name="code" value={formDataForgotPassword.code} onChange={handleChangeForgotPassword} placeholder="Code" required />
                            <button className="button-form" type="submit">Validate Code Forgot Password</button>
                        </form>
                    </>
                }
                {!forgotPasswordCodeSuccess && <p style={{ color: 'red' }}>{forgotPasswordCodeMessage}</p>}
                {
                    forgotPasswordCodeSuccess &&
                    <>
                        <p style={{ color: 'green' }}>{forgotPasswordCodeMessage}</p>
                        <form onSubmit={handleSubmitChangePassword}>
                            <input className="input-form" type="password" name="new_password" value={formDataForgotPassword.new_password} onChange={handleChangeForgotPassword} placeholder="New Password" required />
                            <button className="button-form" type="submit">Submit New Password</button>
                        </form>
                    </>
                }
                {!forgotPasswordChangeSuccess && <p style={{ color: 'red' }}>{forgotPasswordChangeMessage}</p>}
                {forgotPasswordChangeSuccess && <p style={{ color: 'green' }}>{forgotPasswordChangeMessage}</p>}
                
            </div>
            <div className="signup">
                <h2>Select Table</h2>
                <br/>
                <br/>
                <br/>
                <select className="select-table" value={selectedTable} onChange={handleTableSelect}>
                    {tableSchemas.map((schema, index) => (
                        <option key={index} value={schema}>{schema}</option>
                    ))}
                </select>
                <div class = "down_note"></div>
                <button className="button-form" onClick={handleTableGet}>Submit</button>
            </div>
            <div className="signup">
                {tableColumns.length > 0 && (
                    <table className="data-table">
                        <thead>
                            <tr>
                                {tableColumns.map(column => (
                                    <th key={column}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>
                                            {typeof cell === 'object' ? (
                                                <pre>{JSON.stringify(cell, null, 2)}</pre>
                                            ) : (
                                                cell
                                            )}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
}

export default ApiTesting;
