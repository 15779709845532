import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './FormPage.css';
import { URL } from './constants.js';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useTranslation } from 'react-i18next'

const FormPage = forwardRef((props, ref) => {
    const { formName } = useParams();
    const [formConfig, setFormConfig] = useState(null);
    const [formData, setFormData] = useState({});
    const [listTextRows, setListTextRows] = useState([]);
    const [listCheckBoxText, setListCheckBoxText] = useState([]);
    const [listCheckBoxNum, setListCheckBoxNum] = useState([]);
    const [listNumericRows, setNumericRows] = useState([]);
    const [listDatetimeRows, setDatetimeRows] = useState([]);
    const [hint, setHint] = useState({});
    const { t } = useTranslation();
    const [formDataHistory, setFormDataHistory] = useState({});

    useImperativeHandle(ref, () => {
        return {
            generatePDF: handleSubmit,
        }
    })
    let globalDiscount = 0;
    let globalDiscountSet = false;
    let globalDiscountType = "";

    const addListTextRow = () => {
        setListTextRows(prevRows => [...prevRows, {}]);
    };

    const addListCheckBoxRow = () => {
        setListCheckBoxText(prevRows => [...prevRows, {}]);
    };

    const addListCheckBoxNumRow = () => {
        setListCheckBoxNum(prevRows => [...prevRows, {}]);
    };

    const addListDatetimeRow = () => {
        setDatetimeRows(prevRows => [...prevRows, {}]);
    };

    const addNumerictRow = () => {
        setNumericRows(prevRows => [...prevRows, {}]);
    };

    const removeListTextRow = (index) => {
        setListTextRows(prevRows => prevRows.filter((_, idx) => idx !== index));
    };

    const removeListCheckBoxTextRow = (index) => {
        setListCheckBoxText(prevRows => prevRows.filter((_, idx) => idx !== index));
    };

    const removeListCheckBoxNumRow = (index) => {
        setListCheckBoxNum(prevRows => prevRows.filter((_, idx) => idx !== index));
    };

    const removeListDatetimeRow = (index) => {
        setDatetimeRows(prevRows => prevRows.filter((_, idx) => idx !== index));
    };

    const removeNumericRow = (index) => {
        setNumericRows(prevRows => prevRows.filter((_, idx) => idx !== index));
    };

    const onChangeHint = (event) => {
        const {id, value} = event.target
        setHint(prevHint => ({
            ...prevHint,
            [id]: value
        }))
    }
    const onChooseHint = (e, text) => {
        let id = e.target.getAttribute("target_input")
        console.log(id)
        let el = document.querySelector('#' + id)
        el.value = text
        setHint(prevHint => ({
            ...prevHint,
            [id]: text
        }))
        document.querySelector('.hint-row[target_input="' + id + '"]').classList.add('d-none');
    }
    
    useEffect(() => {
        async function fetchUserFormData(decodedToken){
            const response = await fetch(`${URL}/api/form_handler/get_form_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({user_id: decodedToken.id})
            });
            return await response.json();
        };


        const token = Cookies.get('login-token-jwt');
        if (token) {
            const decodedToken = jwtDecode(token);
            const expirationTime = decodedToken.exp * 1000;
            if (Date.now() >= expirationTime) {
                Cookies.remove('login-token-jwt');
            } else {
                const resp = fetchUserFormData(decodedToken)
                resp.then(data => {
                    if(data.valid_data){
                        setFormDataHistory(data.data);
                    }  
                })
            }
        }
    }, []);
    
    useEffect(() => {
        async function fetchConfigForm(){
            try {
                const response = await axios.get(`${URL}/api/config/get_config_by_name/${formName}`);
                setFormConfig(response.data);
                initializeFormData(response.data);
            } catch (error) {
                console.error('Error fetching form config:', error);
            }
        };
        
        fetchConfigForm();
    }, [formName]);

    const initializeFormData = (config) => {
        const data = {};
        config.form_fields_list.forEach((page) => {
            page.fields.forEach((field) => {
                if (field.field_type === "NUMERIC_DISCOUNT"){
                    data[`${page.page_form_id}-${field.field_name_id}`] = {
                        type: field.field_type,
                        description: field.field_description,
                        object_inserted: '',
                        object_price: '',
                        object_quantity: 0,
                        discount_type: '%',
                        discount_ammount: ''
                    };
                }
                else if (field.field_type === "LIST_TEXT"){
                    data[`${page.page_form_id}-${field.field_name_id}`] = {};
                    for (let i = 0; i <= 50; i++){
                        data[`${page.page_form_id}-${field.field_name_id}`][i] = {
                            type: field.field_type,
                            description: field.field_description,
                            value: ''
                        }
                    }
                }
                else if (field.field_type === "LIST_NUMERIC_DISCOUNT"){
                    data[`${page.page_form_id}-${field.field_name_id}`] = {}
                    for (let i = 0; i <= 50; i++){
                        data[`${page.page_form_id}-${field.field_name_id}`][i] = {
                            type: field.field_type,
                            description: field.field_description,
                            object_inserted: '',
                            object_price: '',
                            object_quantity: '',
                            discount_type: '%',
                            discount_ammount: ''
                        };
                    }
                }
                else if (field.field_type === "LIST_DATETIME_TEXT"){
                    data[`${page.page_form_id}-${field.field_name_id}`] = {}
                    for (let i = 0; i <= 50; i++){
                        data[`${page.page_form_id}-${field.field_name_id}`][i] = {
                            type: field.field_type,
                            description: field.field_description,
                            text: '',
                            datetime: '',
                        };
                    }
                }
                else if (field.field_type === "GLOBAL_DISCOUNT"){
                    data[`${page.page_form_id}-${field.field_name_id}.GLOBAL`] = {
                        type: field.field_type,
                        description: field.field_description,
                        text_discount: '',
                        value_discount: '',
                        type_discount: '%'
                    };
                }
                else if (field.field_type === "CHECK_BOX_TEXT"){
                    data[`${page.page_form_id}-${field.field_name_id}`] = {
                        type: field.field_type,
                        description: field.field_description,
                        check_box_tick: false,
                        check_box_text: ''
                    };
                }
                else if (field.field_type === "CHECK_BOX_NUMERIC_ADD"){
                    data[`${page.page_form_id}-${field.field_name_id}`] = {
                        type: field.field_type,
                        description: field.field_description,
                        check_box_tick: false,
                        check_box_numeric: 0
                    };
                }
                else if (field.field_type === "LIST_CHECK_BOX_TEXT"){
                    data[`${page.page_form_id}-${field.field_name_id}`] = {}
                    for (let i = 0; i <= 50; i++){
                        data[`${page.page_form_id}-${field.field_name_id}`][i] = {
                            type: field.field_type,
                            description: field.field_description,
                            check_box_tick: false,
                            check_box_text: ''
                        };
                    }
                }
                else if (field.field_type === "LIST_CHECK_BOX_NUMERIC_ADD"){
                    data[`${page.page_form_id}-${field.field_name_id}`] = {}
                    for (let i = 0; i <= 50; i++){
                        data[`${page.page_form_id}-${field.field_name_id}`][i] = {
                            type: field.field_type,
                            description: field.field_description,
                            check_box_tick: false,
                            check_box_numeric: 0
                        };
                    }
                }
                else {
                    data[`${page.page_form_id}-${field.field_name_id}`] = {
                        type: field.field_type,
                        description: field.field_description,
                        value: ''
                    };
                }
            });
        });
        setFormData(data);
    };

    const handleInputChange = (id, fieldName, fieldType, fieldDescription, idx, value, type_num, check_box_obj) => {
        if (fieldType === "NUMERIC_DISCOUNT") {
            const numericRegex = /^[0-9]*(\.[0-9]*)?$/;
            if (value === '%' || value === 'Ded') {
                setFormData((prevData) => ({
                    ...prevData,
                    [`${id}-${fieldName}`]:{
                        ...prevData[`${id}-${fieldName}`],
                        discount_type: value,
                    },
                }));
            }
            else if (numericRegex.test(value)) {
                if (type_num === "discount"){
                    setFormData((prevData) => ({
                        ...prevData,
                        [`${id}-${fieldName}`]:{
                            ...prevData[`${id}-${fieldName}`],
                            discount_ammount: value,
                        },
                    }));
                }
                else if (type_num === "pret") {
                    setFormData((prevData) => ({
                        ...prevData,
                        [`${id}-${fieldName}`]:{
                            ...prevData[`${id}-${fieldName}`],
                            object_price: value,
                        },
                    }));
                }
                else if (type_num === "quantity") {
                    setFormData((prevData) => ({
                        ...prevData,
                        [`${id}-${fieldName}`]:{
                            ...prevData[`${id}-${fieldName}`],
                            object_quantity: value,
                        },
                    }));
                }
                else {
                    setFormData((prevData) => ({
                        ...prevData,
                        [`${id}-${fieldName}`]:{
                            ...prevData[`${id}-${fieldName}`],
                            object_inserted: value,
                        },
                    }));
                }
            }
            else {
                setFormData((prevData) => ({
                    ...prevData,
                    [`${id}-${fieldName}`]:{
                        ...prevData[`${id}-${fieldName}`],
                        object_inserted: value,
                    },
                }));
            }
        }
        else if (fieldType === "LIST_TEXT") {
            setListTextRows(prevRows => {
                const newRows = [...prevRows];
                newRows[idx] = value;
                return newRows;
            });

            setFormData((prevData) => ({
                ...prevData,
                [`${id}-${fieldName}`]:{
                    ...prevData[`${id}-${fieldName}`],
                    [`${idx}`]: {
                        type: "LIST_TEXT",
                        description: fieldDescription,
                        value: value
                    }
                },
            }));
        }
        else if (fieldType === "LIST_NUMERIC_DISCOUNT"){
            setNumericRows(prevRows => {
                const newRows = [...prevRows];
                newRows[idx] = value;
                return newRows;
            });
            const updatedFormData = {...formData };
            const numericRegex = /^[0-9]*(\.[0-9]*)?$/;

            if (value === '%' || value === 'Ded') {
                updatedFormData[`${id}-${fieldName}`][`${idx}`]["discount_type"] = value
            }
            else if (numericRegex.test(value)) {
                if (type_num === "discount"){
                    updatedFormData[`${id}-${fieldName}`][`${idx}`]["discount_ammount"] = value;
                }
                else if (type_num === "pret") {
                    updatedFormData[`${id}-${fieldName}`][`${idx}`]["object_price"] = value;
                }
                else if (type_num === "quantity") {
                    updatedFormData[`${id}-${fieldName}`][`${idx}`]["object_quantity"] = value;
                }
                else {
                    updatedFormData[`${id}-${fieldName}`][`${idx}`]["object_inserted"] = value;
                }
            }
            else{
                updatedFormData[`${id}-${fieldName}`][`${idx}`]["object_inserted"] = value;
            }

            setFormData(updatedFormData);
        }
        else if (fieldType === "LIST_DATETIME_TEXT") {
            const updatedFormData = {...formData };
            if (type_num === "text_datetime") {
                updatedFormData[`${id}-${fieldName}`][`${idx}`]["text"] = value;
            }
            else {
                updatedFormData[`${id}-${fieldName}`][`${idx}`]["datetime"] = value;
            }
            setFormData(updatedFormData);
        }
        else if (fieldType === "GLOBAL_DISCOUNT") {
            const updatedFormData = {...formData };
            if (type_num === "global_discount_text") {
                updatedFormData[`${id}-${fieldName}.GLOBAL`]["text_discount"] = value;
            }
            else if (type_num === "global_discount_value") {
                updatedFormData[`${id}-${fieldName}.GLOBAL`]["value_discount"] = value;
            }
            else {
                updatedFormData[`${id}-${fieldName}.GLOBAL`]["type_discount"] = value;
            }
            setFormData(updatedFormData);
        }
        else if (fieldType === "CHECK_BOX_TEXT") {
            const updatedFormData = {...formData };
            if (check_box_obj === "TEXT") {
                updatedFormData[`${id}-${fieldName}`]["check_box_text"] = value;
            }
            else {
                updatedFormData[`${id}-${fieldName}`]["check_box_tick"] = value;
            }
            setFormData(updatedFormData);
        }
        else if (fieldType === "CHECK_BOX_NUMERIC_ADD") {
            const updatedFormData = {...formData };
            if (check_box_obj === "NUM") {
                updatedFormData[`${id}-${fieldName}`]["check_box_numeric"] = value;
            }
            else {
                updatedFormData[`${id}-${fieldName}`]["check_box_tick"] = value;
            }
            setFormData(updatedFormData);
        }
        else if (fieldType === "LIST_CHECK_BOX_TEXT") {
            setListCheckBoxText(prevRows => {
                const newRows = [...prevRows];
                newRows[idx] = value;
                return newRows;
            });

            const updatedFormData = {...formData };
            if (check_box_obj === "TEXT") {
                updatedFormData[`${id}-${fieldName}`][idx]["check_box_text"] = value;
            }
            else {
                updatedFormData[`${id}-${fieldName}`][idx]["check_box_tick"] = value;
            }
            setFormData(updatedFormData);
        }
        else if (fieldType === "LIST_CHECK_BOX_NUMERIC_ADD") {
            setListCheckBoxText(prevRows => {
                const newRows = [...prevRows];
                newRows[idx] = value;
                return newRows;
            });

            const updatedFormData = {...formData };
            if (check_box_obj === "NUM") {
                updatedFormData[`${id}-${fieldName}`][idx]["check_box_numeric"] = value;
            }
            else {
                updatedFormData[`${id}-${fieldName}`][idx]["check_box_tick"] = value;
            }
            setFormData(updatedFormData);
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [`${id}-${fieldName}`]: {
                    type: fieldType,
                    value: value,
                    description: fieldDescription
                },
            }));
        }
    };

    const retainFirstNElements = (obj, n) => {
        const newObj = {};

        for (let i = 0; i < n; i++) {
            newObj[i] = obj[i];
            newObj[i]["nr"] = i + 1;
        }

        return newObj;
    };

    const deductPercentage = (numberStr, baseNumber) => {
        const percentage = parseFloat(numberStr);
        const amountToDeduct = (percentage / 100) * baseNumber;
        const result = baseNumber - amountToDeduct;
    
        return result;
    };

    const applyDiscount = (item) => {
        if (item.object_price !== "" && item.discount_ammount !== "") {
            if (item.discount_type === "%") {
                if (globalDiscountSet) {
                    if (globalDiscountType === "%") {
                        let extra_deduct = deductPercentage(100 - globalDiscount, item.object_price);
                        item.object_price = deductPercentage(item.discount_ammount, item.object_price) - extra_deduct;
                    } else {
                        item.object_price = deductPercentage(item.discount_ammount, item.object_price) - globalDiscount;
                    }
                } else {
                    
                    item.object_price = deductPercentage(item.discount_ammount, item.object_price);
                }
            } else {
                if (globalDiscountSet) {
                    if (globalDiscountType === "%") {
                        let extra_deduct = deductPercentage(100 - globalDiscount, item.object_price);
                        item.object_price = item.object_price - parseFloat(item.discount_ammount) - extra_deduct;
                    } else {
                        item.object_price = item.object_price - parseFloat(item.discount_ammount) - globalDiscount;
                    }
                } else {
                    const fl_nb = parseFloat(item.discount_ammount);
                    item.object_price = item.object_price - fl_nb;
                }
            }
        }
    };

    const generatePDF = (data) => {
        const doc = new jsPDF();
        let currentY = 10;
        let currentPageId = null;
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;

        const addTextEntry = (item) => {
            let description = "";
            let value = "";
            switch (item.type) {
                case "TEXT":
                    description = `${item.description}`;
                    value = `${item.value}`;
                    break;
                case "NUMERIC":
                    description = `${item.description}`;
                    value = `${item.value}\n`;
                    break;
                case "NUMERIC_DISCOUNT":
                    applyDiscount(item);
                    description = `${item.description}\n`;
                    value = `- Nume: ${item.object_inserted}\n- Pret dupa Reducere: ${item.object_price}\n- Cantitate: ${item.object_quantity}\n- Tipul deducerii: ${item.discount_type}\n- Cantitatea redusa: ${item.discount_ammount}`;
                    break;
                case "DROPDOWN":
                    description = `${item.description}`;
                    value = `${item.value}`;
                    break;
                case "CHECK_BOX":
                    description = `${item.description}`;
                    value = `${item.value}`;
                    break;
                case "DATETIME":
                    description = `${item.description}`;
                    value = `${item.value}`;
                    break;
                case "GLOBAL_DISCOUNT":
                    description = `${item.description}\n`;
                    value = `- Text Discount: ${item.text_discount}\n- Tipul Deducerii: ${item.type_discount}\n- Cantitatea Dedusa: ${item.value_discount}\n`;
                    break;
                case "CHECK_BOX_TEXT":
                    description = `${item.description}\n`;
                    value = `- ${item.check_box_text}: ${item.check_box_tick}`;
                    break;
                case "CHECK_BOX_NUMERIC_ADD":
                    description = `${item.description}`;
                    value = `\n- ${item.check_box_tick}: ${item.check_box_numeric}`;
                    break;
                default:
                    break;
            }
    
            const splitTextDescription = doc.splitTextToSize(description, pageWidth / 2 - 20);
            const splitTextValue = doc.splitTextToSize(value, pageWidth / 2 - 20);
            let maxLength = Math.max(splitTextDescription.length, splitTextValue.length);

            let textLines = splitTextDescription.length * 2.2;
            let lineHeight = 2;
            
            let textLines2 = splitTextValue.length * 2.2;
            if(textLines2 > textLines){
                textLines = textLines2
            }
            
            if (currentY + lineHeight * textLines > pageHeight - 20) {
                doc.addPage();
                currentY = 12;
            }
            doc.setFillColor(220, 220, 220);
            doc.rect(10, currentY - 8, pageWidth / 2 - 10, 12 + (5 * (maxLength - 1)), 'F');
            doc.text(splitTextDescription, 12, currentY);
            doc.text(splitTextValue, pageWidth / 2 + 12, currentY);
            doc.line(pageWidth / 2, currentY + (4 + 5 * (maxLength - 1)), doc.internal.pageSize.getWidth() - 10, currentY + (4 + 5 * (maxLength - 1)));
            doc.setDrawColor(220, 220, 220);
            doc.line(10, currentY + (4 + 5 * (maxLength - 1)), pageWidth / 2, currentY + (4 + 5 * (maxLength - 1)));
            doc.setDrawColor(0, 0, 0);
            currentY += lineHeight * textLines + 12;
        };
    
       // Setting global discount values
        Object.keys(data).forEach((key) => {
            const item = data[key];

            if (item.type === "GLOBAL_DISCOUNT") {
                if (item.value_discount !== "") {
                    globalDiscount = parseFloat(item.value_discount);
                    globalDiscountSet = true;
                    globalDiscountType = item.type_discount;
                }
            }
        });
    
        Object.keys(data).forEach((key) => {
            const pageId = key.split("-")[0];
            const item = data[key];
    
            if (pageId !== currentPageId) {
                if (currentPageId !== null) {
                    currentY += 10;
                }
                doc.setFont("helvetica", "bold");
                let textToSize = doc.splitTextToSize(pageId.toUpperCase(), doc.internal.pageSize.width - 20);
                let lines = textToSize.length;
    
                if (currentY + 2 * lines > pageHeight - 20) {
                    doc.addPage();
                    currentY = 10;
                }
                doc.text(textToSize, 10, currentY);
                doc.line(10, currentY + 5, doc.internal.pageSize.getWidth() - 10, currentY + 5);
                doc.setFont("helvetica", "normal");
                currentPageId = pageId;
                currentY += 2 * lines + 14;
            }
    
            if (!item.type) {
                const itemType = item["0"].type;
                let itemLength = 0;
                let tableColumns = [];
                let columnStyles = {};
    
                switch (itemType) {
                    case "LIST_TEXT":
                        itemLength = listTextRows.length;
                        tableColumns = [
                            { header: 'Nr.', dataKey: "nr" },
                            { header: 'Descriptie', dataKey: 'description' },
                            { header: 'Obiect', dataKey: 'value' }
                        ];
                        columnStyles = {
                            0: { cellWidth: 10 },
                            1: { cellWidth: 30 },
                            2: { cellWidth: 40 },
                        };
                        break;
                    case "LIST_NUMERIC_DISCOUNT":
                        itemLength = listNumericRows.length;
                        tableColumns = [
                            { header: 'Nr.', dataKey: "nr" },
                            { header: 'Descriptie', dataKey: 'description' },
                            { header: 'Obiect', dataKey: 'object_inserted' },
                            { header: 'Tip Discount', dataKey: 'discount_type' },
                            { header: 'Pret Obiect', dataKey: 'object_price' },
                            { header: 'Numar bucati', dataKey: 'object_quantity' },
                        ];
                        columnStyles = {
                            0: { cellWidth: 10 },
                            1: { cellWidth: 30 },
                            2: { cellWidth: 40 },
                            3: { cellWidth: 40 },
                            4: { cellWidth: 40 },
                            5: { cellWidth: 40 },
                            6: { cellWidth: 40 },
                        };
    
                        for (let i = 0; i < itemLength; i++) {
                            applyDiscount(item[i]);
                        }
                        break;
                    case "LIST_DATETIME_TEXT":
                        itemLength = listDatetimeRows.length;
                        tableColumns = [
                            { header: 'Nr.', dataKey: "nr" },
                            { header: 'Descriptie', dataKey: 'description' },
                            { header: 'Data', dataKey: 'datetime' },
                            { header: 'Text', dataKey: 'text' },
                        ];
                        columnStyles = {
                            0: { cellWidth: 10 },
                            1: { cellWidth: 30 },
                            2: { cellWidth: 40 },
                            3: { cellWidth: 60 },
                        };
                        break;
                    case "LIST_CHECK_BOX_TEXT":
                        itemLength = listCheckBoxText.length;
                        tableColumns = [
                            { header: 'Nr.', dataKey: "nr" },
                            { header: 'Descriptie', dataKey: 'description' },
                            { header: 'Text', dataKey: 'check_box_text' },
                            { header: 'Bifat', dataKey: 'check_box_tick' },
                        ];
                        columnStyles = {
                            0: { cellWidth: 10 },
                            1: { cellWidth: 30 },
                            2: { cellWidth: 80 },
                            3: { cellWidth: 20 },
                        };
                        break;
                    case "LIST_CHECK_BOX_NUMERIC_ADD":
                        itemLength = listCheckBoxNum.length;
                        tableColumns = [
                            { header: 'Nr.', dataKey: "nr" },
                            { header: 'Descriptie', dataKey: 'description' },
                            { header: 'Text', dataKey: 'check_box_numeric' },
                            { header: 'Bifat', dataKey: 'check_box_tick' },
                        ];
                        columnStyles = {
                            0: { cellWidth: 10 },
                            1: { cellWidth: 30 },
                            2: { cellWidth: 80 },
                            3: { cellWidth: 20 },
                        };
                        break;
                    default:
                        break;
                }
    
                const filteredData = retainFirstNElements(item, itemLength);
                const tableRows = Object.keys(filteredData)
                    .filter((k) => !isNaN(k))
                    .map((index) => filteredData[index]);

                if (pageId !== currentPageId) {
                    doc.text(`Page ID: ${pageId}`, 10, currentY);
                    currentY += 10;
                }

                doc.autoTable({
                    head: [tableColumns.map((col) => col.header)],
                    body: tableRows.map((row) => tableColumns.map((col) => row[col.dataKey])),
                    startY: currentY,
                    margin: { top: 10, bottom: 10, left: 10 },
                    tableWidth: 'wrap',
                    columnStyles: columnStyles
                });

                currentY = doc.autoTable.previous.finalY + 10; 
            } else {
                addTextEntry(item);
            }
        });
    
        doc.save('form-data.pdf');
    };

    const handleSubmit = async () => {
        try {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                } else {
                    await fetch(`${URL}/api/form_handler/post_form_data`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({form_data: formData, user_id: decodedToken.id})
                    });

                    // Maybe remove this in final version
                    const response = await fetch(`${URL}/api/form_handler/get_form_data`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({user_id: decodedToken.id})
                    });
                    const responseData = await response.json();
                    if(responseData.valid_data){
                        setFormDataHistory(responseData.data);
                    }
                    generatePDF(formData);
                }
            }
            else {
                alert(`Submited with success this:\n ${formData}`);
                generatePDF(formData);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to submit form.');
        }
    };

    const handleNextForm = (e) => {
        var index =  parseInt(e.target.getAttribute('index-btn'));
        e.target.classList.add('d-none');
        var nextBtn = document.querySelector('[index-btn="'+(index+1)+'"]');
        nextBtn.classList.remove('d-none');
        var form_name = nextBtn.getAttribute('form_name');
        var tabs = document.getElementsByClassName('tab');
        for(let idx=0;idx < tabs.length;idx++){
            tabs[idx].classList.remove('active');
        }
        document.querySelector('.tab[form_name="'+form_name+'"]').classList.add('active');
        
        var forms = document.getElementsByClassName('form_container');
        for(let idx=0;idx < forms.length;idx++){
            forms[idx].classList.add('d-none');
        }
        document.getElementById(form_name).classList.remove('d-none')
    };

    const handleHighlight = (id, e) => {
        const form_name = e.target.getAttribute('form_name');
        var tabs = document.getElementsByClassName('tab');
        for(let index=0;index < tabs.length;index++){
            tabs[index].classList.remove('active');
        }
        e.target.classList.add('active');

        var forms = document.getElementsByClassName('form_container');
        for(let index=0;index < forms.length;index++){
            forms[index].classList.add('d-none');
        }
        document.getElementById(form_name).classList.remove('d-none')

        var btns = document.getElementsByClassName('to-hide-btn');
        for(let index=0;index < forms.length;index++){
            btns[index].classList.add('d-none');
        }
        var idx = e.target.getAttribute('idx');
        document.querySelector('.to-hide-btn[index-btn="'+idx+'"]').classList.remove('d-none');
    };

    return (
        <>
            {
                
                formConfig && (
                    <div className='login-div form-page-div w-100'>
                        <h2 className="header-2-config">{formConfig.form_name}</h2>
                        <p className="title-header-config mt-3 mb-3">{formConfig.form_description}</p>
                        <div className="forms-browser-container mb-3">
                        {
                            formConfig.form_fields_list.map((page, index) => (
                                <button idx={index} form_name={`${page.page_form_name}`} className={index === 0 ? "tab active" : "tab"}
                                    onClick={(e) => handleHighlight(page.page_form_name, e)}
                                >
                                    {index + 1}. {page.page_form_name}{'  '}
                                </button>
                            ))
                        }
                        </div>
                       
                        {formConfig.form_fields_list.map((page, index) => (
                            <>
                                <div key={index} id={page.page_form_name} className={ index !== 0 ? "form_container card w-50 d-none" : "form_container card w-50"}>
                                    <div className="card-body">
                                        <p className="card-title">{index + 1} . {page.page_form_name}</p>
                                        <p className="card-text mt-1 mb-2 text-body-secondary"><b>{page.page_form_description}</b></p>
                                        {
                                            page.fields.map((field, idx) => (
                                                    <>
                                                        <div key={idx} className={["CHECK_BOX", "CHECK_BOX_TEXT"].includes(field.field_type) ? 'd-flex mb-3 mt-3' : 'mb-3 mt-3'}>
                                                            {
                                                                getFieldElement(
                                                                    page.page_form_id, field, formData[field.field_name_id], (idx, value, type_num, check_box_obj) =>
                                                                    handleInputChange(page.page_form_id, field.field_name_id, field.field_type, field.field_description, idx, value, type_num, check_box_obj)
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    index === formConfig.form_fields_list.length - 1 &&
                                    <>
                                        <button index-btn={index} form_name={page.page_form_name} className={index != 0 ? 'to-hide-btn btn btn-success mb-3 mt-3 d-none' : 'to-hide-btn btn btn-success mb-3 mt-3'} type="submit" onClick={handleSubmit}>{t('genereaza_pdf')}</button>
                                    </>
                                }
                                {
                                    index !== formConfig.form_fields_list.length - 1 &&
                                    <>
                                        <button index-btn={index} form_name={page.page_form_name} className={index !== 0 ? 'to-hide-btn btn btn-success mb-3 mt-3 d-none' : 'to-hide-btn btn btn-success mb-3 mt-3'} type="submit" onClick={handleNextForm}>{t('pasul_urmator')}</button>
                                    </>
                                }
                            </>
                        ))}
                    </div>
                )
            }
        </>
    );


    function getFieldElement(id, field, value, onChange) {
        switch (field.field_type) {
            case 'TEXT':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value}
                            hint={hint[`${id}-${field.field_name_id}`]}
                            placeholder={field.field_placeholder}
                            onChange={(e) => {onChange(-1, e.target.value, -1, -1); onChangeHint(e); }}
                        />
                        <div className="hints-list">
                            {
                                formDataHistory[id + '-' + field.field_name_id] && formDataHistory[id + '-' + field.field_name_id]
                                        .filter(item => {
                                        if(!hint[id + '-' + field.field_name_id]){
                                            return false
                                        }
                                        const searchTerm = hint[id + '-' + field.field_name_id].toLowerCase()
                                        const itemText = item.value.toLowerCase()
                                        return searchTerm && itemText && itemText.includes(searchTerm) && itemText !== searchTerm
                                    }).map((hint) =>
                                        <div target_input={`${id}-${field.field_name_id}`} onClick={(e) => onChooseHint(e, hint.value)} className="hint-row">{hint.value}</div>
                                )
                            }
                        </div>
                    </>
                );
            case 'DROPDOWN':
                return (
                <>
                        <label className="form-label">{field.field_description}</label>
                        <select
                        id={`${id}-${field.field_name_id}`}
                        value={value}
                        onChange={(e) => onChange(-1, e.target.value, -1, -1)}
                        className="form-select"
                    >
                        {
                            field.field_options.map((option, idx) => (
                                    <option key={idx} value={option}>{option}</option>
                                )
                            )
                        }
                    </select>
                </>
            );
            case 'CHECK_BOX':
                return (
                    <>
                        <div className="form-check">
                            <label className="form-check-label">{field.field_description}</label>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`${id}-${field.field_name_id}`}
                                checked={value}
                                onChange={(e) => onChange(-1, e.target.checked, -1, -1)}
                                name={`${id}-${field.field_name_id}`}
                            />
                        </div>
                    </>
                );
            case 'CHECK_BOX_TEXT':
                return (
                    <>
                    <div className="d-flex col-12 gap-2">
                        <label className="form-check-label">{field.field_description}</label>
                        <input 
                            type="text" 
                            className="form-control"
                            id={`${id}-${field.field_name_id}`} 
                            value={value}
                            placeholder={field.field_placeholder}
                            onChange={(e) => onChange(-1, e.target.value, -1, "TEXT")}
                        />
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`${id}-${field.field_name_id}`}
                            checked={value}
                            onChange={(e) => onChange(-1, e.target.checked, -1, "BOOL")}
                            name={`${id}-${field.field_name_id}`}
                        />
                        <label for={`${id}-${field.field_name_id}`}>{field.field_description}</label>
                    </div>
                    </>
                );
            case 'CHECK_BOX_NUMERIC_ADD':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value} 
                            onChange={(e) => onChange(-1, e.target.value, -1, "NUM")}
                            placeholder={field.field_placeholder}
                        />
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`${id}-${field.field_name_id}`}
                            checked={value}
                            onChange={(e) => onChange(-1, e.target.checked, -1, "BOOL")}
                            name={`${id}-${field.field_name_id}`}
                        />
                        <label for={`${id}-${field.field_name_id}`}>{field.field_description}</label>
                    </>
                );
            case 'LIST_CHECK_BOX_TEXT':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        {
                            listCheckBoxText.map((row, index) => (
                                <div key={index}>
                                    <input 
                                        type="text" 
                                        className="form-control mb-3" 
                                        value={value}
                                        placeholder={`Element ${index + 1}`}
                                        onChange={(e) => onChange(index, e.target.value, -1, "TEXT")}
                                    />
                                    <div className="d-flex align-items-center gap-2 mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`${id}-${field.field_name_id}-${index + 1}`}
                                            checked={value}
                                            onChange={(e) => onChange(index, e.target.checked, -1, "BOOL")}
                                            name={`${id}-${field.field_name_id}`}
                                        />
                                        <label for={`${id}-${field.field_name_id}-${index + 1}`}>{`Element ${index + 1}`}</label>
                                    </div>
                                    <button type="button" className='btn btn-danger mb-3' onClick={() => removeListCheckBoxTextRow(index)}>Sterge Obiect</button>
                                </div>
                            ))
                        }
                        <button type="button" className='btn btn-success' onClick={addListCheckBoxRow}>Adauga Obiect</button>
                    </>
                );
            case 'LIST_CHECK_BOX_NUMERIC_ADD':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        {
                            listCheckBoxNum.map((row, index) => (
                                <div key={index}>
                                    <input 
                                        type="number"
                                        className="form-control mb-3" 
                                        value={value}
                                        placeholder={`Element ${index + 1}`}
                                        onChange={(e) => onChange(index, e.target.value, -1, "NUM")}
                                    />
                                    <div className="d-flex align-items-center gap-2 mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`${id}-${field.field_name_id}-${index + 1}`}
                                            checked={value}
                                            onChange={(e) => onChange(index, e.target.checked, -1, "BOOL")}
                                            name={`${id}-${field.field_name_id}`}
                                        />
                                        <label for={`${id}-${field.field_name_id}-${index + 1}`}>{`Element ${index + 1}`}</label>
                                    </div>
                                    <button type="button" className='btn btn-danger mb-3' onClick={() => removeListCheckBoxNumRow(index)}>Sterge Obiect</button>
                                </div>
                            ))
                        }
                        <button type="button" className='btn btn-success' onClick={addListCheckBoxNumRow}>Adauga Obiect</button>
                    </>
                );
            case 'DATETIME':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        <input
                            type="date"
                            className="form-control"
                            id={`${id}-${field.field_name_id}`}
                            value={field.field_anme_id == 'intrare_service_constatare' ? new Date().toISOString().substr(0, 10) : value}
                            placeholder={field.field_placeholder}
                            onChange={(e) => onChange(-1, e.target.value, -1, -1)}
                        />
                    </>
                );
            case 'NUMERIC':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value} 
                            hint={hint[`${id}-${field.field_name_id}`]}
                            onChange={(e) =>{ onChange(-1, e.target.value, -1, -1); onChangeHint(e); }}
                            placeholder={field.field_placeholder}
                        />
                        <div className="hints-list">
                            { formDataHistory[id + '-' + field.field_name_id] && formDataHistory[id + '-' + field.field_name_id]
                            .filter(item => {
                                    if(!hint[id + '-' + field.field_name_id]){
                                        return false
                                    }
                                    const searchTerm = hint[id + '-' + field.field_name_id]
                                    const itemText = item.value
                                    return searchTerm && itemText && itemText.includes(searchTerm) && itemText.toString() !== searchTerm
                                }).map((hint) =>
                                    <div target_input={`${id}-${field.field_name_id}`} onClick={(e) => onChooseHint(e, hint.value)} className="hint-row">{hint.value}</div>
                            )}
                        </div>
                    </>
                );
            case 'NUMERIC_DISCOUNT':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        <input 
                            type="text" 
                            className="form-control mb-3" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value}
                            onChange={(e) => onChange(-1, e.target.value, -1, -1)}
                            placeholder='Obiectul'
                        />
                        <input 
                            type="number" 
                            className="form-control mb-3" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value} 
                            onChange={(e) => onChange(-1, e.target.value, "pret", -1)}
                            placeholder='Pretul Obiectului'
                        />
                        <input 
                            type="number" 
                            className="form-control mb-3" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value} 
                            onChange={(e) => onChange(-1, e.target.value, "discount", -1)}
                            placeholder='Deducerea Obiectului'
                        />
                        <input 
                            type="number" 
                            className="form-control mb-3" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value} 
                            onChange={(e) => onChange(-1, e.target.value, "quantity", -1)}
                            placeholder='Cantitatea Obiectului'
                        />
                        <select
                            id={`${id}-${field.field_name_id}`}
                            value={value}
                            onChange={(e) => onChange(-1, e.target.value, -1, -1)}
                            className="form-select"
                        >
                            <option key="%" value="%">Deducere prin procente (%)</option>
                            <option key="Ded" value="Ded">Deducere prin valoare fixa</option>
                        </select>
                    </>
                );
            case 'LIST_TEXT':
                return (
                    <>
                        <div className="d-flex align-items-center gap-3">
                            <label className="form-label">{field.field_description}</label>
                            {
                                listTextRows.map((row, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => onChange(index, e.target.value, -1)}
                                            placeholder={`Element ${index + 1}`}
                                        />
                                        <button type="button" className='btn btn-danger' onClick={() => removeListTextRow(index)}>Sterge Obiect</button>
                                    </div>
                                ))
                            }
                            <button type="button" className='btn btn-success' onClick={addListTextRow}>Adauga Obiect</button>
                        </div>
                    </>
                );
            case 'LIST_NUMERIC_DISCOUNT':
                return (
                    <>
                            <label className="form-label">{field.field_description}</label>
                            {
                                listNumericRows.map((row, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            value={value}
                                            onChange={(e) => onChange(index, e.target.value, -1, -1)}
                                            placeholder={`Obiectul ${index + 1}`}
                                        />
                                        <input 
                                            type="number" 
                                            className="form-control mb-3" 
                                            id={`${id}-${field.field_name_id}`} 
                                            value={value} 
                                            onChange={(e) => onChange(index, e.target.value, "pret", -1)}
                                            placeholder={`Pretul Obiectului ${index + 1}`}
                                        />
                                        <input
                                            type="number"
                                            className="form-control mb-3"
                                            value={value}
                                            onChange={(e) => onChange(index, e.target.value, "discount", -1)}
                                            placeholder={`Deducerea Obiectului ${index + 1}`}
                                        />
                                        <input 
                                            type="number" 
                                            className="form-control mb-3"
                                            id={`${id}-${field.field_name_id}`} 
                                            value={value} 
                                            onChange={(e) => onChange(index, e.target.value, "quantity", -1)}
                                            placeholder={`Cantitatea Obiectului ${index + 1}`}
                                        />
                                        <select
                                            id={`${id}-${field.field_name_id}`}
                                            value={value}
                                            onChange={(e) => onChange(index, e.target.value, -1, -1)}
                                            className="form-select mb-3"
                                        >
                                            <option key="%" value="%">Deducere prin procente (%)</option>
                                            <option key="Ded" value="Ded">Deducere prin valoare fixa</option>
                                        </select>
                                        <button type="button" className='btn btn-danger mb-3' onClick={() => removeNumericRow(index)}>Sterge Obiect</button>
                                    </div>
                                ))
                            }
                            <button type="button" className='btn btn-success' onClick={addNumerictRow}>Adauga Obiect</button>
                        </>
                );
            case 'LIST_DATETIME_TEXT':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        {
                            listDatetimeRows.map((row, index) => (
                                <div key={index}>
                                    <input 
                                        type="text" 
                                        className="form-control mb-3" 
                                        id={`${id}-${field.field_name_id}`} 
                                        value={value}
                                        placeholder={field.field_placeholder}
                                        onChange={(e) => onChange(index, e.target.value, "text_datetime", -1)}
                                    />
                                    <input
                                        type="date"
                                        className="form-control mb-3"
                                        id={`${id}-${field.field_name_id}`}
                                        value={value}
                                        placeholder={field.field_placeholder}
                                        onChange={(e) => onChange(index, e.target.value, "date_datetime", -1)}
                                    />
                                    <button type="button" className='btn btn-danger mb-3' onClick={() => removeListDatetimeRow(index)}>Sterge Obiect</button>
                                </div>
                            ))
                        }
                        <button type="button" className='btn btn-success' onClick={addListDatetimeRow}>Adauga Obiect</button>
                    </>
                );
            case 'GLOBAL_DISCOUNT':
                return (
                    <>
                        <label className="form-label">{field.field_description}</label>
                        <input 
                            type="text" 
                            className="form-control mb-3" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value}
                            onChange={(e) => onChange(-1, e.target.value, "global_discount_text", -1)}
                            placeholder='Detalii deducere'
                        />
                        <input 
                            type="number" 
                            className="form-control mb-3" 
                            id={`${id}-${field.field_name_id}`} 
                            value={value} 
                            onChange={(e) => onChange(-1, e.target.value, "global_discount_value", -1)}
                            placeholder='Deducerea'
                        />
                        <select
                            id={`${id}-${field.field_name_id}`}
                            value={value}
                            onChange={(e) => onChange(-1, e.target.value, "global_discount_type", -1)}
                            className="form-select"
                        >
                            <option key="%" value="%">Deducere prin procente (%)</option>
                            <option key="Ded" value="Ded">Deducere prin valoare fixa</option>
                        </select>
                    </>
                );
            default:
                return null;
        };
    };
});

export default FormPage;
