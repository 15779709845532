import React, { useState, useEffect } from 'react';
import './Register.css';
import './Login.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { URL } from './constants.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

function Register() {
    const navigate = useNavigate();
    const [formDataRegister, setFormDataRegister] = useState({
        email_hashed: '',
        password_hashed: ''
    });
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [registrationMessage, setRegistrationMessage] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const { t } = useTranslation()

    const handleChangeRegister = (e) => {
        const { name, value } = e.target;
        setFormDataRegister({
            ...formDataRegister,
            [name]: value
        });

        // Check if passwords match
        if (name === 'confirmPassword') {
            setPasswordMatch(formDataRegister.password_hashed === value);
        }
    };

    const handleSubmitRegister = async (e) => {
        e.preventDefault();
        if (!passwordMatch) {
            alert("Passwords do not match!");
            return;
        }
        try {
            setLoader(true);
            // Check Credentials
            const response = await fetch(`${URL}/api/users/check_credentials_register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email_hashed: formDataRegister.email_hashed,
                    password_hashed: formDataRegister.password_hashed
                })
            });
            const are_valid_cred = await response.json();

            if (are_valid_cred["are_good_credentials"] === true) {
                // Create User
                const response = await fetch(`${URL}/api/users/register_user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formDataRegister)
                });
                const data = await response.json();
                setLoader(false);
                if (data["is_created"] === true) {
                    setRegistrationSuccess(true);
                    navigate("/login");
                    window.location.reload();
                }
                else {
                    setRegistrationMessage(`Eroare: ${data["message"]}`);
                    setRegistrationSuccess(false);
                }
            }
            else {
                setLoader(false);
                setRegistrationMessage(`Eroare: ${are_valid_cred["message"]}`);
                setRegistrationSuccess(false);
            }
            
           
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    };

    const handleLogoutRegister = () => {
        const token = Cookies.get('login-token-jwt');
        if (token) {
            Cookies.remove('login-token-jwt');
        }
        setRegistrationSuccess(false);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    setRegistrationSuccess(false);
                } else {
                    setRegistrationSuccess(true);
                }
            }
        }

        setLoader(false);
        fetchData();
    }, []);


    return (
        <>
            {
                registrationSuccess &&
                <>
                    <div className="login-div">
                        <span className='color-text'>{t('esti_deja_contectat_doresti_sa_te_reconectezi')} </span>
                        <span className="color-text hover-text extra_underline" onClick={handleLogoutRegister}>{t('click_aici')}</span>
                    </div>
                </>
            }
            {
                !registrationSuccess &&
                <>
                    <div className="login-div col-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{t('inregistrare')}</h5>
                                <form onSubmit={handleSubmitRegister}>
                                <label className="form-label">Email</label>
                                <input className="form-control mb-3" type="email" name="email_hashed" value={formDataRegister.email_hashed} onChange={handleChangeRegister} required />
                                <label className="form-label">{t('parola')}</label>
                                <input className="form-control mb-3" type="password" name="password_hashed" value={formDataRegister.password_hashed} onChange={handleChangeRegister} required />
                                <label className="form-label">{t('confirma_parola')}</label>
                                <input className="form-control" type="password" name="confirmPassword" value={formDataRegister.confirmPassword} onChange={handleChangeRegister} required />
                                {!passwordMatch && <h6 className='card-subtitle bad-input mt-3'>{t('parolele_nu_sunt_la_fel')}</h6>}
                                <button className="btn btn-success mt-3" type="submit" disabled={loader}>{t('inregistrare')}</button>
                                <h6 className='card-subtitle mt-3'>{t('ai_cont_deja')} <a className="color-text hover-text" href="/login">{t('click_aici')}</a></h6>
                                {!registrationSuccess && <h6 className='card-subtitle bad-input'>{registrationMessage}</h6>}
                            </form>
                            </div>
                        </div>                    
                    </div>
                </>
            }
            {
                !registrationSuccess &&
                loader &&
                <>
                    <div className="loader">
                        <div style={{"--i": 1}}></div>
                        <div style={{"--i": 2}}></div>
                        <div style={{"--i": 3}}></div>
                        <div style={{"--i": 4}}></div>
                    </div>
                </>
            }
        </>
    );
}

export default Register;
