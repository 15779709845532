import './Account.css';
import { useTranslation } from 'react-i18next'


function Account() {
    const { t } = useTranslation()
    return(
        <>
            <h3 className='header-3-config'>content TBD</h3>
        </>
    );
}

export default Account;
