import './AdminNotifications.css';
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { URL } from './constants.js';

function AdminNotifications() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        notification_text: '',
    });
    const [notifications, setNotifications] = useState([]);
    const [selectedNotif, setSelectedNotif] = useState(null);
    const [selectedNotifMessage, setSelectedNotifMessage] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        async function check_for_admin() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    navigate("/");
                    return false;
                } else {
                    try {
                        const response = await fetch(`${URL}/api/users/get_username`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ user_id: decodedToken.id })
                        });
                        const data = await response.json();
                        if (data["user_exists"] === false) {
                            Cookies.remove('login-token-jwt');
                            navigate("/404");
                            return false;
                        } else {
                            const response = await fetch(`${URL}/api/users/is_admin`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ user_id: decodedToken.id })
                            });
                            const data2 = await response.json();
                            if (!data2["is_admin"]) {
                                navigate("/404");
                                return false;
                            }
                            try {
                                setLoader(true);
                                const response = await fetch(`${URL}/api/notifications/get_notifications`, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                });
                                const notif = await response.json()
                                setNotifications(notif.notifications ? notif.notifications : []);
                                setLoader(false);            
                            } catch (error) {
                                setLoader(false);
                                console.error('Error:', error);
                            }
                            return true;
                        }
                    } catch (error) {
                        console.error('Error fetching username:', error);
                    }
                    
                }
            }else {
                navigate("/404");
                return false;
            }
        }

        if(check_for_admin()) {
            setLoader(false);
        }
    }, []);

    const addNotification = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                await fetch(`${URL}/api/notifications/create_notification`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        message: formData.notification_text,
                        user_id: decodedToken.id,
                    })
                });
            }
            setLoader(false);        
            window.location.reload();    
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    };

    const handleFormData = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleRowClick = (notif) => {
        try {
            setSelectedNotif(notif);
            setSelectedNotifMessage(notif.message);
        } catch (error) {
            console.error('Error handling row click:', error);
        }
    };

    const handleChangeMessage = (e) => {
        setSelectedNotifMessage(e.target.value)
    };

    const editNotification = async (notif) => {
        const token = Cookies.get('login-token-jwt');
        const decodedToken = jwtDecode(token);
        await fetch(`${URL}/api/notifications/update_notification`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                message: selectedNotifMessage,
                user_id: decodedToken.id,
                id: notif.id
            })
        });
    };

    const deleteNotification = async (notif) => {
        const token = Cookies.get('login-token-jwt');
        const decodedToken = jwtDecode(token);
        await fetch(`${URL}/api/notifications/delete_notification`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: decodedToken.id,
                id: notif.id
            })
        });
        window.location.reload();    
    };

    return(
        <>
        <h5 className="caption-table mt-3">Notificari</h5>
            <div className="col-6 table-scrollable">
                <table className="table table-striped table-hover caption-top">
                    <thead>
                        <tr>
                            <th>Notificare</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notifications.map(notif => (
                            <tr key={notif.id} onClick={() => handleRowClick(notif)}>
                                <td>{notif.message}</td>
                                <td>{new Date(notif.date).toLocaleString().split(',')[0]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="card col-6">
                <div className="card-body">
                    <h4 className='card-title'>Adauga o notificare</h4>
                    <form onSubmit={addNotification}>
                        <label className="form-label mt-3">Text notificare</label>
                        <textarea className="form-control" rows="5" name="notification_text" value={formData.notification_text} onChange={handleFormData} required ></textarea>
                        <button className="btn btn-success mt-3" type="submit" disabled={loader}>Adauga notificare</button>
                    </form>
                </div>
            </div>
            {
                selectedNotif &&
                <>
                    <div className="card col-6 mt-3">
                        <div className="card-body">
                            <h4 className='card-title'>Adauga o notificare</h4>
                            <label className="form-label mt-3">Text notificare</label>
                            <textarea className="form-control" rows="5" id="id_notification_text_edit" name="notification_text_edit" value={selectedNotifMessage} onChange={handleChangeMessage}></textarea>
                            <div className="d-flex justify-content-center gap-3">
                                <button className="btn btn-primary mt-3" disabled={loader} onClick={() => editNotification(selectedNotif)}>Editeaza notificare</button>
                                <button className="btn btn-danger mt-3" disabled={loader} onClick={() => deleteNotification(selectedNotif)}>Sterge notificare</button>
                            </div>
                        </div>
                    </div>
                </>
            }
            
        </>
    );
}

export default AdminNotifications;
