import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { URL } from './constants.js';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import './AdminConfigAssignment.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next'

function AdminConfigAssignment() {
    const [configs, setConfigs] = useState([]);
    const [users, setUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [formDataUpdateConfig, setFormDataUpdateConfig] = useState({
        config_name: '',
        emails: '',
    });
    const [updateConfigSuccess, setUpdateConfigSuccess] = useState(false);
    const [updateConfigMessage, setUpdateConfigMessage] = useState('');
    const [formDataDeleteConfig, setFormDataDeleteConfig] = useState({
        config_name: '',
        emails_delete: '',
    });
    const [deleteConfigSuccess, setDeleteConfigSuccess] = useState(false);
    const [deleteConfigMessage, setDeleteConfigMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation()

    const fetchDataConfigs = async () => {
        try {
            const response = await axios.get(`${URL}/api/config/get_config_manager`);
            setConfigs(response.data);
        } catch (error) {
            console.error('Error fetching configs:', error);
        }
    };

    const fetchDataUsers = async () => {
        try {
            const response = await axios.get(`${URL}/api/users/get_all_relevant_user_data`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching configs:', error);
        }
    };

    useEffect(() => {
        async function check_for_admin() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    navigate("/");
                    return false;
                } else {
                    try {
                        const response = await fetch(`${URL}/api/users/get_username`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ user_id: decodedToken.id })
                        });
                        const data = await response.json();
                        if (data["user_exists"] === false) {
                            Cookies.remove('login-token-jwt');
                            navigate("/");
                            return false;
                        } else {
                            const response = await fetch(`${URL}/api/users/is_admin`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ user_id: decodedToken.id })
                            });
                            const data2 = await response.json();
                            if (!data2["is_admin"]) {
                                navigate("/");
                                return false;
                            }
                            return true;
                        }
                    } catch (error) {
                        console.error('Error fetching username:', error);
                    }
                }
            }
            else {
                navigate("/");
                return false;
            }
        }

        if(check_for_admin()) {
            fetchDataConfigs();
            fetchDataUsers();
            setLoader(false);
        }
    }, []);

    const handleRowClick = (config) => {
        try {
            setSelectedConfig(config);
        } catch (error) {
            console.error('Error handling row click:', error);
        }
    };

    const handleFormData = (e) => {
        const { name, value } = e.target;
        setFormDataUpdateConfig({
            ...formDataUpdateConfig,
            [name]: value
        });
    };

    const handleFormDataDelete = (e) => {
        const { name, value } = e.target;
        setFormDataDeleteConfig({
            ...formDataDeleteConfig,
            [name]: value
        });
    };

    const updateAddUserConfigManager = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            if (formDataUpdateConfig.config_name === ''){
                formDataUpdateConfig.config_name = document.getElementById('text-update').value;
            }
            const response = await fetch(`${URL}/api/config/add_user_config_assignment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    config_name: formDataUpdateConfig.config_name,
                    email_list: formDataUpdateConfig.emails
                })
            });
            const data = await response.json();
            setLoader(false);
            
            setUpdateConfigSuccess(data["success"]);
            setUpdateConfigMessage(data["message"]);
            fetchDataConfigs();
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    };

    const updateRemoveUserConfigManager = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            if (formDataDeleteConfig.config_name === ''){
                formDataDeleteConfig.config_name = document.getElementById('text-update').value;
            }
            const response = await fetch(`${URL}/api/config/remove_user_config_assignment`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    config_name: formDataDeleteConfig.config_name,
                    email_list: formDataDeleteConfig.emails_delete
                })
            });
            const data = await response.json();
            setLoader(false);
            
            setDeleteConfigSuccess(data["success"]);
            setDeleteConfigMessage(data["message"]);
            fetchDataConfigs();
        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    };

    return (
        <>
            <h5 className="caption-table">{t('toate_configurile_curente')}</h5>
            <div className="col-6 table-scrollable mb-3">
                <table className="table table-striped table-hover caption-top">
                    <thead>
                        <tr>
                            <th>Config ID</th>
                            <th>Config Name</th>
                            <th>Public Config</th>
                            <th>Config Assignment Emails</th>
                            <th>Config Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        {configs.map(config => (
                            <tr key={config.id} onClick={() => handleRowClick(config)}>
                                <td>{config.id}</td>
                                <td>{config.config_name}</td>
                                <td>{`${config.public}`}</td>
                                <td>{config.config_assignments ? config.config_assignments.join(', ') : ''}</td>
                                <td>{config.config_error}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <h5 className="caption-table">{t('toti_utilizatorii_curenti')}</h5>
            <div className="col-6 table-scrollable">
                <table className="table table-striped table-hover caption-top">
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>User Account Type</th>
                            <th>User Email</th>
                            <th>User Username</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.account_type}</td>
                                <td>{user.email_hashed}</td>
                                <td>{user.username_hashed}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex gap-5 col-10 justify-content-center">
                {
                    selectedConfig && 
                    <>
                        <div className='card col-4'>
                            <div className="card-body">
                                <h4 className="card-title">{t('adauga_un_utilizator_pentru_un_config')}</h4>
                                {
                                    loader &&
                                    selectedConfig && 
                                    <>
                                        <div className="loader">
                                            <div style={{"--i": 1}}></div>
                                            <div style={{"--i": 2}}></div>
                                            <div style={{"--i": 3}}></div>
                                            <div style={{"--i": 4}}></div>
                                        </div>
                                    </>
                                }
                                {
                                    updateConfigSuccess &&
                                    selectedConfig && 
                                    <>
                                        <div className="login-div">
                                            <span className='text-success'>{updateConfigMessage}</span>
                                        </div>
                                    </>
                                }
                                {
                                    !updateConfigSuccess &&
                                    selectedConfig && 
                                    <>
                                        <div className="login-div">
                                            <span className='text-danger'>{updateConfigMessage}</span>
                                        </div>
                                    </>
                                }
                                <form onSubmit={updateAddUserConfigManager}>
                                    <input className="form-control mb-3 mt-3" type="text" name="config_name" id="text-update" disabled={true} value={selectedConfig.config_name} onChange={handleFormData} placeholder="Nume config (fara .yml)" required />
                                    <label className="form-label">Emails</label>
                                    <input className="form-control" type="text" name="emails" onChange={handleFormData} required />
                                    <button className="btn btn-success mt-3" type="submit" disabled={loader}>{t('adauga_utilizatori')}</button>
                                </form>
                            </div>
                        </div>
                    </>
                }                
                {
                    selectedConfig && 
                    <>
                        <div className='card col-4'>
                            <div className="card-body">
                                <h4 className="card-title">{t('sterge_un_utilizator_de_la_un_config')}</h4>
                                {
                                    loader &&
                                    selectedConfig && 
                                    <>
                                        <div className="loader">
                                            <div style={{"--i": 1}}></div>
                                            <div style={{"--i": 2}}></div>
                                            <div style={{"--i": 3}}></div>
                                            <div style={{"--i": 4}}></div>
                                        </div>
                                    </>
                                }
                                {
                                    deleteConfigSuccess &&
                                    selectedConfig && 
                                    <>
                                        <div className="login-div">
                                            <span className='text-success'>{deleteConfigMessage}</span>
                                        </div>
                                    </>
                                }   
                                {
                                    !deleteConfigSuccess &&
                                    selectedConfig && 
                                    <>
                                        <div className="login-div">
                                            <span className='text-danger'>{deleteConfigMessage}</span>
                                        </div>
                                    </>
                                }
                                <form onSubmit={updateRemoveUserConfigManager}>
                                    <input className="form-control mb-3 mt-3" type="text" name="config_name" id="text-update" disabled={true} value={selectedConfig.config_name} onChange={handleFormDataDelete} placeholder="Nume config (fara .yml)" required />
                                    <label className="form-label">Emails</label>
                                    <input className="form-control" type="text" name="emails_delete" onChange={handleFormDataDelete} required />
                                    <button className="btn btn-danger mt-3" type="submit" disabled={loader}>{t('sterge_utilizatori')}</button>
                                </form>
                            </div>
                        </div>
                    </>
                }              
            </div>
        </>
    );
}

export default AdminConfigAssignment;
