import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "ace-builds";
import { URL } from './constants.js';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/webpack-resolver';
import './AdminConfigUpdate.css';
import yaml from 'js-yaml';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next'

function AdminConfigUpdate() {
    const [configs, setConfigs] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [clickedOnConfig, setClickedOnConfig] = useState(false);
    const [updateMessageSuccess, setUpdateMessageSuccess] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation()

    const fetchData = async () => {
        try {
            const response = await axios.get(`${URL}/api/config/get_all_configs`);
            setConfigs(response.data);
        } catch (error) {
            console.error('Error fetching configs:', error);
        }
    };

    useEffect(() => {
        async function check_for_admin() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    navigate("/");
                    return false;
                } else {
                    try {
                        const response = await fetch(`${URL}/api/users/get_username`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ user_id: decodedToken.id })
                        });
                        const data = await response.json();
                        if (data["user_exists"] === false) {
                            Cookies.remove('login-token-jwt');
                            navigate("/");
                            return false;
                        } else {
                            const response = await fetch(`${URL}/api/users/is_admin`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ user_id: decodedToken.id })
                            });
                            const data2 = await response.json();
                            if (!data2["is_admin"]) {
                                navigate("/");
                                return false;
                            }
                            return true;
                        }
                    } catch (error) {
                        console.error('Error fetching username:', error);
                    }
                }
            }
            else {
                navigate("/");
                return false;
            }
        }

        if (check_for_admin()){
            fetchData();
            setClickedOnConfig(false);
            setIsPublic(false);
        }
    }, []);

    const handleRowClick = (config) => {
        try {
            setSelectedConfig(config);
            setClickedOnConfig(true);
        } catch (error) {
            console.error('Error handling row click:', error);
        }
    };

    const handleEditorChange = (newValue) => {
        try {
            const updatedConfig = yaml.load(newValue);
            setSelectedConfig(updatedConfig);
        } catch (e) {
            
        }
    };

    const handleUpdateConfig = async () => {
        try {
            if (selectedConfig) {
                console.log(selectedConfig);
                if ("config_file_name" in selectedConfig){
                    console.log(selectedConfig);
                    setLoader(true);
                    const response = await fetch(`${URL}/api/config/update_config`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            config: selectedConfig,
                            public: isPublic
                        })
                    });
                    const data = await response.json();
                    setLoader(false);

                    setUpdateMessageSuccess(data["success"]);
                    setUpdateMessage(data["message"]);
                }
                else {
                    setUpdateMessageSuccess(false);
                    setUpdateMessage("config_file_name este necesar pentru a incerca sa actualizezi fisierul");
                }
            } else {
                console.error('No config selected.');
            }
        } catch (error) {
            console.error('Error updating config:', error);
        }
    };

    const handlePublicConfig = (e) => {
        const { name, value } = e.target;
        setIsPublic(value);
    };

    return (
        <>
            <div className="col-10 table-scrollable">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Config Name</th>
                            <th>Form Name ID</th>
                            <th>Form Display Name</th>
                            <th>Form Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {configs.map(config => (
                            <tr key={config.form_name_id} onClick={() => handleRowClick(config)}>
                                <td>{config.config_file_name}</td>
                                <td>{config.form_name_id}</td>
                                <td>{config.form_display_name}</td>
                                <td>{config.form_description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="col-10">
                {
                    selectedConfig &&
                    loader &&
                    <>
                        <div className="loader">
                            <div style={{"--i": 1}}></div>
                            <div style={{"--i": 2}}></div>
                            <div style={{"--i": 3}}></div>
                            <div style={{"--i": 4}}></div>
                        </div>
                        <br/>
                    </>
                }
                {
                    selectedConfig &&
                    !updateMessageSuccess &&
                    <>
                        <div className="login-div fp-div">
                            <span className='text-danger'>{updateMessage}</span>
                        </div>
                    </>
                }
                {
                    selectedConfig &&
                    updateMessageSuccess &&
                    <>
                        <div className="login-div fp-div">
                            <span className='text-success'>{updateMessage}</span>
                        </div>
                    </>
                }
                {
                    clickedOnConfig && 
                    <>
                        <div className="col-6 m-auto mb-3">
                            <label className="form-label">{t('este_public')} {selectedConfig?.config_file_name || ''}.yaml ?</label>
                            <select
                                id="public"
                                name="public"
                                onChange={handlePublicConfig}
                                className='form-control'
                            >
                                <option key={false} value={false}>False</option>
                                <option key={true} value={true}>True</option>
                            </select>
                        </div>
                    </>
                }
                {
                    clickedOnConfig && 
                    <>
                        <h3 className="mb-3">{t('nume_fisier')}: {selectedConfig?.config_file_name || ''}.yaml</h3>
                        <AceEditor
                            mode="yaml"
                            theme="monokai"
                            name="yamlEditor"
                            className="ace_editor_class"
                            onChange={handleEditorChange}
                            fontSize={14}
                            lineHeight={19}
                            showPrintMargin={false}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={yaml.dump(selectedConfig)}
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                showLineNumbers: true,
                                tabSize: 4,
                                useSoftTabs: true,
                            }}
                        />
                        <button onClick={handleUpdateConfig} disabled={loader} className='btn btn-success mt-3'>{t('actualizeaza_formularul_pentru')} {selectedConfig?.config_file_name || ''}.yaml</button>
                    </>
                }
            </div>
        </>
    );
}

export default AdminConfigUpdate;
