import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ConfigList from './ConfigList';
import FormPage from './FormPage';
import NotFound from './NotFound';
import ApiTesting from './ApiTesting';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import Cookies from 'js-cookie';
import Help from './Help';
import Contact from './Contact';
import Admin from './Admin';
import AdminConfigUpdate from './AdminConfigUpdate';
import AdminConfigAssignment from './AdminConfigAssignment';
import AdminConfigCrd from './AdminConfigCrd';
import Documents from './Documents';
import AdminNotifications from './AdminNotifications';
import Account from './Account';
import { jwtDecode } from 'jwt-decode';
import { URL } from './constants.js';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import {useRef} from "react";
import { FaBell } from "react-icons/fa";

function App() {
    const [loginSuccessApp, setLoginSuccessApp] = useState(false);
    const [username, setUsername] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const [lang, setLang] = useState('');
    const [notifications, setNotifications] = useState([]);

    const { t } = useTranslation()
    const formRef = useRef();
    useEffect(() => {
        setCurrentPath(window.location.pathname)
        const currentLanguageCode = cookies.get('i18next') || 'ro'
        setLang(currentLanguageCode)
        async function fetchData() {
            const token = Cookies.get('login-token-jwt');
            if (token) {
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000;
    
                if (Date.now() >= expirationTime) {
                    Cookies.remove('login-token-jwt');
                    setLoginSuccessApp(false);
                } else {
                    try {
                        const response = await fetch(`${URL}/api/users/get_username`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ user_id: decodedToken.id })
                        });
                        const data = await response.json();
                        if (data["user_exists"] === false) {
                            Cookies.remove('login-token-jwt');
                            setLoginSuccessApp(false);
                        } else {
                            setLoginSuccessApp(true);
                            setUsername(data["username"]);

                            const response = await fetch(`${URL}/api/users/is_admin`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ user_id: decodedToken.id })
                            });
                            const data2 = await response.json();
                            if (data2["is_admin"]) {
                                setIsAdmin(true);
                            }
                            else {
                                setIsAdmin(false);
                            }
                        }
                    } catch (error) {
                        console.error('Error fetching username:', error);
                    }
                    try {
                        const response = await fetch(`${URL}/api/notifications/get_notifications`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        });
                        const notif = await response.json();
                        setNotifications(notif.notifications ? notif.notifications : []);
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
            }
        }

        // Theme handler
        const button = document.querySelector("[data-theme-toggle]");
        const localStorageTheme = localStorage.getItem("theme");
        const systemSettingDark = window.matchMedia("(prefers-color-scheme: dark)");
        let currentThemeSetting = calculateSettingAsThemeString({ localStorageTheme, systemSettingDark });
        

        updateButton({ buttonEl: button, isDark: currentThemeSetting === "dark" });
        updateThemeOnHtmlEl({ theme: currentThemeSetting });
        button.addEventListener("click", (event) => {
            const newTheme = currentThemeSetting === "dark" ? "light" : "dark";
        
            localStorage.setItem("theme", newTheme);
            updateButton({ buttonEl: button, isDark: newTheme === "dark" });
            updateThemeOnHtmlEl({ theme: newTheme });
        
            currentThemeSetting = newTheme;
        }); 
        handleMobileMenu();
        fetchData();
    }, []);

    const handleLogout = () => {
        const token = Cookies.get('login-token-jwt');
        if (token) {
            Cookies.remove('login-token-jwt');
        }
        setLoginSuccessApp(false);
    };

    function calculateSettingAsThemeString({ localStorageTheme, systemSettingDark }) {
    if (localStorageTheme !== null) {
            return localStorageTheme;
        }

        if (systemSettingDark.matches) {
            return "light";
        }

        return "light";
    }

    function updateButton({ buttonEl, isDark }) {
        const newCta = isDark ? "Tema Luminoasa" : "Tema Intunecata";

        buttonEl.setAttribute("aria-label", newCta);
        buttonEl.innerText = newCta;
    }

    function updateThemeOnHtmlEl({ theme }) {
        document.querySelector("html").setAttribute("data-theme", theme);
    }

    function handleMobileMenu(){
        const menu = document.querySelector(".menu");
        const menuMain = menu.querySelector(".menu-main");
        const goBack = menu.querySelector(".go-back");
        const menuTrigger = document.querySelector(".mobile-menu-trigger");
        const closeMenu = menu.querySelector(".mobile-menu-close");
        let subMenu;
        menuMain.addEventListener("click", (e) =>{
        if(!menu.classList.contains("active")){
            return;
        }
        if(e.target.closest(".menu-item-has-children")){
            const hasChildren = e.target.closest(".menu-item-has-children");
            showSubMenu(hasChildren);
        }
        });
        goBack.addEventListener("click",() =>{
            hideSubMenu();
        })
        menuTrigger.addEventListener("click",() =>{
            toggleMenu();
        })
        closeMenu.addEventListener("click",() =>{
            toggleMenu();
        })
        document.querySelector(".menu-overlay").addEventListener("click",() =>{
            toggleMenu();
        })
        function toggleMenu(){
            menu.classList.toggle("active");
            document.querySelector(".menu-overlay").classList.toggle("active");
        }
        function showSubMenu(hasChildren){
            subMenu = hasChildren.querySelector(".sub-menu");
            subMenu.classList.add("active");
            subMenu.style.animation = "slideLeft 0.5s ease forwards";
            const menuTitle = hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
            menu.querySelector(".current-menu-title").innerHTML=menuTitle;
            menu.querySelector(".mobile-menu-head").classList.add("active");
        }
    
        function  hideSubMenu(){  
            subMenu.style.animation = "slideRight 0.5s ease forwards";
            setTimeout(() =>{
                subMenu.classList.remove("active");	
            },300); 
            menu.querySelector(".current-menu-title").innerHTML="";
            menu.querySelector(".mobile-menu-head").classList.remove("active");
        }
    
        window.onresize = function(){
            if(this.innerWidth >991){
                if(menu.classList.contains("active")){
                    toggleMenu();
                }
            }
        }
    }
    return (
        <Router>
            <div className="App">
                <header className="header">
                    <div className="container-header">
                        <div className="row-header v-center">
                            <div className="header-item item-left">
                                <div className="logo">
                                    <a href="/">la-moldo.ro</a>
                                </div>
                            </div>

                            <div className="header-item item-center">
                                <div className="menu-overlay">
                                </div>
                                <nav className="menu">
                                    <div className="mobile-menu-head">
                                        <div className="go-back"><i className="fa fa-angle-left"></i></div>
                                        <div className="current-menu-title"></div>
                                        <div className="mobile-menu-close">&times;</div>
                                    </div>
                                    <ul className="menu-main">
                                        {
                                            loginSuccessApp &&
                                            <>
                                                <li className={ currentPath === '/history' ? 'active' : ''}><a href="/">{t('istoric')}</a></li>
                                            </>
                                        }
                                        <li className={ currentPath === '/documents' || currentPath.includes('/form') ? 'active' : ''}><a href="/documents">{t('documente')}</a></li>
                                        {
                                            loginSuccessApp &&
                                            <>
                                                <li className={ currentPath === '/account' ? 'active' : ''}><a href='/account'>{t('buna')} {username} !</a></li>
                                                <li className="menu-item-has-children notif-dropdown">
                                                    <span className="position-relative">
                                                        <FaBell/>
                                                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                                                            <span className="visually-hidden">New alerts</span>
                                                        </span>
                                                    </span>
                                                    <div className="sub-menu">
                                                        <ul>
                                                        {
                                                            notifications.slice(0, 5).map(notif => (
                                                                <li className="notif-li">
                                                                    {notif.message} <br/>
                                                                    <small>{new Date(notif.date).toLocaleString().split(',')[0]}</small>
                                                                </li>
                                                            ))
                                                        }
                                                        </ul>
                                                    </div>
                                                </li>
                                                
                                            </>
                                        }
                                        {
                                            loginSuccessApp &&
                                            isAdmin &&
                                            <>
                                                <li className={ currentPath.includes('/admin') && !currentPath.includes('notifications') ? 'active' : ''}><a href="/admin">Admin Dashboard</a></li>
                                                <li className={ currentPath.includes('/admin/notifications') ? 'active' : ''}><a href="/admin/notifications">Notificari</a></li>
                                            </>
                                        }
                                        {
                                            currentPath.includes('/form/') &&
                                            <>
                                                <li className={ currentPath.includes('/form/') ? 'active' : ''} onClick={() => formRef.current.generatePDF()}>Genereaza PDF</li>
                                            </>
                                        }
                                        <li className={ currentPath === '/help' ? 'active' : ''}><a href="/help">{t('ajutor')}</a></li>
                                        <li className={ currentPath === '/contact' ? 'active' : ''}><a href="/contact">{t('contact')}</a></li>
                                        {
                                            !loginSuccessApp &&
                                            <>
                                                <li><button className="btn btn-secondary"><a href="/login">{t('autentificare')}</a></button></li>
                                                <li><button className="btn btn-dark"><a href="/register">{t('inregistrare')}</a></button></li>
                                            </>
                                        }
                                        {
                                            loginSuccessApp &&
                                            <>
                                                <li><button className="btn btn-dark"><a href="/" onClick={handleLogout}>{t('deconectare')}</a></button></li>
                                            </>
                                        }
                                        <li className="menu-item-has-children languages-menu">
                                            <img src={"https://flagsapi.com/" + (lang === 'en' ? 'GB' : 'RO') + "/flat/32.png"} /> <b>{lang === 'en' ? 'EN' : 'RO'}</b>
                                            <div className="sub-menu lang-dropdown">
                                                <ul>
                                                    <li onClick={() => { i18next.changeLanguage(lang == 'ro' ? 'en' : 'ro'); setLang(lang === 'ro' ? 'en' : 'ro'); }}>
                                                        {
                                                            lang === 'ro' &&
                                                            <>
                                                                <img src="https://flagsapi.com/GB/flat/32.png" /> <b>EN</b>
                                                            </>
                                                        }
                                                          {
                                                            lang === 'en' &&
                                                            <>
                                                                <img src="https://flagsapi.com/RO/flat/32.png" /> <b>RO</b>
                                                            </>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <button
                                                className='button-form-login-theme d-none'
                                                type="button"
                                                data-theme-toggle
                                                aria-label="Tema Luminoasa"
                                            >Tema Luminoasa</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="mobile-wrapper header-item">
                                <div className="mobile-menu-trigger">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mt-3">
                    <header className="App-header">
                        <Routes>
                            <Route path="*" element={<NotFound />} />
                            <Route path="/404" element={<NotFound />} />
                            <Route path="/" element={<ConfigList />} />
                            <Route path="/api_testing" element={<ApiTesting />} />
                            <Route path="/form/:formName" element={<FormPage ref={formRef} />} />
                            <Route path="/documents/" element={<Documents />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/forgot_password" element={<ForgotPassword />} />
                            <Route path="/help" element={<Help />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/admin" element={<Admin />} />
                            <Route path="/account/" element={<Account />} />
                            <Route path="/admin/config_update" element={<AdminConfigUpdate />} />
                            <Route path="/admin/config_assignment" element={<AdminConfigAssignment />} />
                            <Route path="/admin/config_create_delete" element={<AdminConfigCrd />} />
                            <Route path="/admin/notifications" element={<AdminNotifications />} />
                            
                        </Routes>
                    </header>
                </div>
            </div>
        </Router>
    );
}

export default App;
